/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-countup components
import CountUp from "react-countup";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import SoftTypography from "../../../../components/SoftTypography";
import SoftBox from "../../../../components/SoftBox";
import { Box } from "@mui/material";

// Soft UI Dashboard PRO React components

function ThinCounterCard({ color, count, title, description, prefix, suffix }) {
  return (
    <Box sx={{ height: '100%' }}>
      <SoftBox p={1} textAlign="center" lineHeight={1}>
        <SoftTypography variant="h1" color={color} fontWeight="bold" textGradient>
          {prefix && (
            <SoftTypography component="span" variant="h5" fontWeight="bold">
              {prefix}
            </SoftTypography>
          )}
          <SoftBox display="inline-block" mx={0.5}>
            <CountUp end={count} duration={1} separator="," />
          </SoftBox>
          {suffix && (
            <SoftTypography component="span" variant="h5" fontWeight="bold">
              {suffix}
            </SoftTypography>
          )}
        </SoftTypography>
        <SoftTypography variant="h6" fontWeight="bold" textTransform="capitalize">
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography
            variant="button"
            fontWeight="regular"
            opacity={0.8}
            textTransform="capitalize"
          >
            {description}
          </SoftTypography>
        )}
      </SoftBox>
    </Box>
  );
}

// Setting default values for the props of ThinCounterCard
ThinCounterCard.defaultProps = {
  color: "info",
  prefix: "",
  suffix: "",
  description: "",
};

// Typechecking props for the BlogCard
ThinCounterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default ThinCounterCard;
