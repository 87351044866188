import { lazy, Suspense } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import MainLayout from "../layouts/main";
// guards
import AuthGuard from "../guards/AuthGuard";
import GuestGuard from "../guards/GuestGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import ClaimsGuard from "../guards/ClaimsGuard";
import DemoGuard from "../guards/DemoGuard";
import JourneyGuard from "../guards/JourneyGuard";
import ProspectGuard from "../guards/ProspectGuard";
import Billing from "../layouts/pages/account/billing";
import UserSettings from "../layouts/pages/account/settings";
import AllProjects from "../layouts/pages/profile/all-projects";
import CompanyProfile from "../layouts/pages/profile/company-profile";
import ProfileOverview from "../layouts/pages/profile/profile-overview";
import ActivitiesList from "../layouts/products/activities-list";
import ProductPage from "../layouts/products/product-page";
import WorkplaceList from "../layouts/products/workplace-list";
import WpHome from "../pages/dashboard/intro/WpHome";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};
// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Subscribe = Loadable(lazy(() => import("../pages/authentication/Subscribe")));
const Register = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);
// Dashboard

//INTRO
const Home = Loadable(lazy(() => import("../pages/dashboard/intro/Home")));
const WorkplacerHome = Loadable(lazy(() => import("../pages/dashboard/intro/WorkplacerHome.js")));

const GlobalStrategySetup = Loadable(lazy(() => import("../pages/dashboard/globalStrategy/GlobalStrategy.js")));
// POTENTIAL

const Prospect = Loadable(lazy(() => import("../pages/dashboard/prospect/Prospect.js")));

// DISCOVER
const DeepDiveControlPanel = Loadable(lazy(() => import("../pages/dashboard/discover/DeepDiveControlPanel.js")));
const DeepDive = Loadable(lazy(() => import("../pages/dashboard/discover/DeepDive.js")));
const Midpoint = Loadable(lazy(() => import("../pages/dashboard/discover/Midpoint.js")));
const WorkplaceUnderstand = Loadable(lazy(() => import("../pages/dashboard/discover/WorkplaceUnderstand.js")));
// STRATEGY
const JourneyStrategy = Loadable(lazy(() => import("../pages/dashboard/strategy/JourneyStrategy.js")));

// PROGRAM
const JourneyProgram = Loadable(lazy(() => import("../pages/dashboard/program/JourneyProgram.js")));

// SEARCH

const SearchAd = Loadable(lazy(() => import("../pages/dashboard/search/SearchAd.js")));
const Solution = Loadable(lazy(() => import("../pages/dashboard/search/Solution.js")));
const PremisesSuggestions = Loadable(lazy(() => import("../pages/dashboard/search/PremisesSuggestions")));

const AiAssistant = Loadable(lazy(() => import("../pages/dashboard/AiAssistant")));


// Main
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")));
const NotFound = Loadable(lazy(() => import("../pages/Page404")));

export default function Router() {
  return useRoutes([
    {
      path: "demo/*",
      element: (
        <DemoGuard>
          <DashboardNavbar absolute />
          <DashboardLayout />
        </DemoGuard>
      ),
      children: [
        { element: <Navigate to="/demo/overview" replace /> },
        { element: <Home /> },
        { path: "", element: <Home /> },
        { element: <Navigate to="/" replace /> },
        { path: "app", element: <AllProjects /> },
        { path: "admin", element: <AllProjects /> },
        { path: "prospect", element: <Prospect /> },
        { path: "controlpanel", element: <WpHome /> },
        {
          path: "overview", element: (
            /* <MgmtGuard> */
            <Home />
            /*  </MgmtGuard> */
          )
        },
        {
          path: "profile",
          children: [
            { element: <Navigate to="/dashboard/profile/overview" replace /> },
            { path: "overview", element: <ProfileOverview /> },
            { path: "company", element: <CompanyProfile /> },
            { path: "settings", element: <UserSettings /> },
          ],
        },
        {
          path: "portfolio",
          children: [
            { path: "overview", element: <Home /> },
            { path: "workplace-strategy", element: <GlobalStrategySetup /> },
          ]
        },
        {
          path: "prospect",
          children: [

            {
              path: "prospect", element: (

                <ProspectGuard>
                  <Prospect />
                </ProspectGuard>
              )
            },
          ]
        },
        {
          path: 'survey',
          children: [
            {
              path: "survey", element: (
                <JourneyGuard>
                  <DeepDiveControlPanel />
                </JourneyGuard>
              )
            },
          ]
        },
        {
          path: 'journey',
          children: [
            {
              path: "understand", element: (
                <JourneyGuard>
                  <WorkplaceUnderstand />
                </JourneyGuard>
              )
            },
            {
              path: "strategize", element: (
                <JourneyGuard>
                  <JourneyStrategy />
                </JourneyGuard>
              )
            },
            {
              path: "program", element: (
                <JourneyGuard>
                  <JourneyProgram />
                </JourneyGuard>
              )
            },
            {
              path: "solution", element: (
                <JourneyGuard>
                  <Solution />
                </JourneyGuard>
              )
            },
            {
              path: "marketplacer", element: (
                <JourneyGuard>
                  <PremisesSuggestions />
                </JourneyGuard>
              )
            },
            {
              path: "search", element: (
                <JourneyGuard>
                  <SearchAd />
                </JourneyGuard>
              )
            }
          ]
        },
      ]
    },

    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Subscribe />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "subscribe", element: <Subscribe /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },
    {
      path: "deepdive/:journey_id",
      element: <DeepDive />
    },
    {
      path: "deepdive/:journey_id/:deepdive_id",
      element: <DeepDive />
    },
    {
      path: "midpoint/:journey_id",
      element: <Midpoint />
    },
    {
      path: "midpoint/:journey_id/:midpoint_id",
      element: <Midpoint />
    },
    /* 
        {
          path: "profile",
          element: (
    
            <AuthGuard>
              <IntroGuard>
                <ClaimsGuard>
                  <DashboardLayout />
                </ClaimsGuard>
              </IntroGuard>
            </AuthGuard>
          ),
          children: [
            { element: <Navigate to="/dashboard/profile/overview" replace /> },
            { path: "overview", element: <ProfileOverview /> },
            { path: "company", element: <CompanyProfile /> },
            { path: "settings", element: <UserSettings /> },
            { path: "billing", element: <Billing /> },
          ],
        }, */
    // Dashboard Routes
    {
      path: "/",
      element: (
        <AuthGuard>
          {/* <IntroGuard> */}
          <ClaimsGuard>
            {/*  <MgmtGuard> */}
            <DashboardNavbar absolute />
            <DashboardLayout />
            {/* </MgmtGuard> */}
          </ClaimsGuard>

          {/* </IntroGuard> */}
        </AuthGuard>
      ),
      children: [
        { element: <Home /> },
        { path: "", element: <Home /> },
        { element: <Navigate to="/" replace /> },
        { path: "app", element: <AllProjects /> },
        { path: "admin", element: <AllProjects /> },
        { path: "prospect", element: <Prospect /> },
        { path: "controlpanel", element: <WpHome /> },
        {
          path: "overview", element: (
            /* <MgmtGuard> */
            <Home />
            /*  </MgmtGuard> */
          )
        },
        {
          path: "profile",
          children: [
            { element: <Navigate to="/dashboard/profile/overview" replace /> },
            { path: "overview", element: <ProfileOverview /> },
            { path: "company", element: <CompanyProfile /> },
            { path: "settings", element: <UserSettings /> },
            { path: "billing", element: <Billing /> },
          ],
        },
        {
          path: "dashboard",
          children: [
            { element: <Navigate to="/dashboard/overview" replace /> },
            { path: "overview", element: <ProductPage /> },
            { path: "potential", element: <WorkplacerHome /> },

          ],
        },
        {
          path: "portfolio",
          children: [
            { path: "overview", element: <Home /> },
            { path: "workplace-strategy", element: <GlobalStrategySetup /> },
          ]
        },
        {
          path: "prospect",
          children: [

            {
              path: "prospect", element: (

                <ProspectGuard>
                  <Prospect />
                </ProspectGuard>
              )
            },
          ]
        },
        {
          path: 'survey',
          children: [
            {
              path: "survey", element: (
                <JourneyGuard>
                  <DeepDiveControlPanel />
                </JourneyGuard>
              )
            },
          ]
        },
        {
          path: 'journey',
          children: [
            {
              path: "understand", element: (
                <JourneyGuard>
                  <WorkplaceUnderstand />
                </JourneyGuard>
              )
            },
            {
              path: "strategize", element: (
                <JourneyGuard>
                  <JourneyStrategy />
                </JourneyGuard>
              )
            },
            {
              path: "program", element: (
                <JourneyGuard>
                  <JourneyProgram />
                </JourneyGuard>
              )
            },
            {
              path: "solution", element: (
                <JourneyGuard>
                  <Solution />
                </JourneyGuard>
              )
            },
            {
              path: "marketplacer", element: (
                <JourneyGuard>
                  <PremisesSuggestions />
                </JourneyGuard>
              )
            },
            {
              path: "search", element: (
                <JourneyGuard>
                  <SearchAd />
                </JourneyGuard>
              )
            }
          ]
        },
        {
          path: "ai",
          children: [

            { element: <Navigate to="/ai/assistant" replace /> },
            { path: "surveyindividual", element: <DeepDive /> },
            { path: "assistant", element: <AiAssistant /> },
          ],
        },
        {
          path: "workplaces",
          children: [
            { element: <Navigate to="/dashboard/workplaces/list" replace /> },
            { path: "list", element: <WorkplaceList /> },
            { path: ":id", element: <ProductPage /> },
            { path: "page", element: <ProductPage /> },
          ],
        },
        {
          path: "activities",
          children: [
            { element: <Navigate to="/dashboard/activities/list" replace /> },
            { path: "list", element: <ActivitiesList /> },
            { path: ":id", element: <ProductPage /> },
            { path: "page", element: <ProductPage /> },
          ],
        },/* 
        {
          path: "projects",
          children: [
            { element: <Navigate to="/projects/projects" replace /> },
            { path: "projects", element: <AllProjects /> },
            { path: "new", element: <NewProject /> },
            { path: "newscenario", element: <NewScenario /> },
            { path: "dashboard", element: <Dashboard /> },
            { path: "edit/:id", element: <EditProject /> },
          ],
        }, */
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "comingsoon", element: <ComingSoon /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },

    {
      path: "/",
      element: <MainLayout />,
      children: [{ element: <LandingPage /> }],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
