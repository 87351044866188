import {
  Alert, Box, Card, Grid,
  Icon,
  Snackbar,
  Stack
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import Geocode from 'react-geocode';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { geoConfig } from '../../../config';
import { BorderBeam } from '../../../examples/borderbeam';
import useLocales from '../../../hooks/useLocales';
import { getCompany, updateCompany } from '../../../redux/slices/company';
import { dispatch } from '../../../redux/store';
import LoadingScreen from '../../LoadingScreen';
import SoftBox from '../../SoftBox';
import SoftButton from '../../SoftButton';
import SoftDropzone from '../../SoftDropzone';
import SoftInput from '../../SoftInput';
import SoftSelect from '../../SoftSelect';
import SoftTypography from '../../SoftTypography';
import axios from 'axios';

// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);
const WORK_MODELS = [
  { name: "Fully Office", description: "All employees are expected to work in the office", value: 100 },
  { name: "4/1", description: "4 days in the office, 1 day remote", value: 80 },
  { name: "3/2", description: "3 days in the office, 2 days remote", value: 60 },
  { name: "2/3", description: "2 days in the office, 3 days remote", value: 40 },
  { name: "1/4", description: "1 day in the office, 4 days remote", value: 20 },
  { name: "Fully Remote", description: "All employees are expected to work remotely", value: 0 },
]
// ----------------------------------------------------------------------

export default function CompanyForm() {

  const { translate } = useLocales();
  const { company } = useSelector((state) => state.company);
  const [openSnack, setOpenSnack] = useState(false);
  const [editLogo, setEditLogo] = useState(false);
  const [editPresentation, setEditPresentation] = useState(false);
  const { 
    industry = '', 
    employees = '', 
    org_number = '', 
    work_model = '', 
    primary_color = '', 
    secondary_color = '', 
    presentation = null, 
    logo = null, 
    zip = '', 
    country = '', 
    city = '', 
    address = '', 
    website_url = '' 
  } = company || {};
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const NewMissionSchema = Yup.object().shape({
    name: Yup.string()
  });
  async function updateLogo(file) {
    console.log('file got to form', file);
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/updatefiles/${company?.id}`;
    const formData = new FormData();
    formData.append('logo', file.file);

    try {
      const response = await axios.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(getCompany(company?.id));
      setEditLogo(false);
      return response.data;
    } catch (error) {
      console.error('Error updating logo:', error);
      throw error;
    }
  }
  async function updatePresentation(file) {
    console.log('file got to form', file);
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/updatefiles/${company?.id}`;
    const formData = new FormData();
    formData.append('presentation', file.file);
    try {
      const response = await axios.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(getCompany(company?.id));
      setEditPresentation(false);
      return response.data;
    } catch (error) {
      console.error('Error updating presentation:', error);
      throw error;

    }

  }

  const formik = useFormik({

    initialValues: { work_model, industry, employees, org_number, primary_color, secondary_color, presentation, logo, zip, country, city, address, website_url },
    validationSchema: NewMissionSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await updateFields(values);
        setSubmitting(false);
        enqueueSnackbar('Post success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  if (!company) return <LoadingScreen />;
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  const updateFields = () => {
    const obj = { ...values, has_strategy_base: !company?.has_strategy_base };
    console.log(obj, 'obj');
    dispatch(updateCompany(company?.id, obj));
  }
  function handleEdi() {
    console.log('Starting updating fields');
    updateFields(values);
  }
  const dizabled = company?.has_strategy_base;
  return (
    <Box>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 2, mt: 2 }}>
                <BorderBeam />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                      Company Information
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>

                    <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                      business information
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SoftBox >
                      <SoftTypography variant="h6" color={'primary'} textGradient fontWeight={"bold"}>Industry</SoftTypography>
                    </SoftBox>
                    {dizabled ? (
                      <SoftTypography variant="body2">
                        {company?.industry}
                      </SoftTypography>
                    ) : (
                      <SoftInput
                        fullWidth
                        placeholder={translate('Industry')}
                        {...getFieldProps('industry')}
                        error={Boolean(touched.industry && errors.industry)}
                        helperText={touched.industry && errors.industry}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox>
                      <SoftTypography variant="h6" color={'primary'} fontWeight={"bold"} textGradient>Website</SoftTypography>
                    </SoftBox>
                    {dizabled ? (
                      <SoftTypography variant="body2">
                        {company?.website_url}
                      </SoftTypography>
                    ) : (
                      <SoftInput
                        fullWidth
                        placeholder={translate('Website')}
                        {...getFieldProps('website_url')}
                        error={Boolean(touched.website_url && errors.website_url)}
                        helperText={touched.website_url && errors.website_url}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                      business address
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SoftBox>
                      <SoftTypography variant="h6" color={'primary'} fontWeight={"bold"} textGradient>Address</SoftTypography>
                    </SoftBox>
                    {dizabled ? (
                      <SoftTypography variant="body2">
                        {company?.address}
                      </SoftTypography>
                    ) : (
                      <SoftInput
                        fullWidth
                        placeholder={translate('Address')}
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                        success={!touched.address || !errors.address}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox>
                      <SoftTypography variant="h6" color={'primary'} fontWeight={"bold"} textGradient>Zip</SoftTypography>
                    </SoftBox>
                    {dizabled ? (
                      <SoftTypography variant="body2">
                        {company?.zip}
                      </SoftTypography>
                    ) : (
                      <SoftInput
                        fullWidth
                        placeholder={translate('Zip')}
                        {...getFieldProps('zip')}
                        error={Boolean(touched.zip && errors.zip)}
                        helperText={touched.zip && errors.zip}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox>
                      <SoftTypography variant="h6" color={'primary'} fontWeight={"bold"} textGradient>City</SoftTypography>
                    </SoftBox>
                    {dizabled ? (
                      <SoftTypography variant="body2">
                        {company?.city}
                      </SoftTypography>
                    ) : (
                      <SoftInput
                        fullWidth
                        placeholder={translate('City')}
                        {...getFieldProps('city')}
                        error={Boolean(touched.city && errors.city)}
                        helperText={touched.city && errors.city}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox>
                      <SoftTypography variant="h6" color={'primary'} fontWeight={"bold"} textGradient>Country</SoftTypography>
                    </SoftBox>
                    {dizabled ? (
                      <SoftTypography variant="body2">
                        {company?.country}
                      </SoftTypography>
                    ) : (
                      <SoftInput
                        fullWidth
                        placeholder={translate('Country')}
                        {...getFieldProps('country')}
                        error={Boolean(touched.country && errors.country)}
                        helperText={touched.country && errors.country}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                      presentation
                    </SoftTypography>
                    <SoftBox>
                      <SoftTypography variant="h6" color={'primary'} fontWeight={"bold"} textGradient>Company Presentation</SoftTypography>
                    </SoftBox>
                    <SoftTypography variant="body2" sx={{ mb: 2 }}>
                      {company?.presentation ? (
                        `Presentation file: ${company?.presentation.name}`
                      ) : (
                        "No Presentation attached"
                      )}
                    </SoftTypography>
                    <Stack spacing={1} direction="row" justifyContent="space-between">
                      {company?.presentation && (
                        <SoftBox>
                          <SoftButton variant="gradient" color="primary" href={company?.presentation?.url} target="_blank">View Presentation</SoftButton>
                        </SoftBox>
                      )}
                      {!dizabled && (
                        !editPresentation ? (
                          <SoftButton variant="gradient" color="warning" onClick={() => setEditPresentation(true)}>
                            Edit Presentation
                          </SoftButton>
                        ) : (
                          <Stack spacing={2}>
                            <SoftDropzone url={`https://plejsapp-api.herokuapp.com/api/v1/companies/updatepresentation/${company?.id}`} onFile={updatePresentation} options={{ addRemoveLinks: true }} />
                            <SoftButton variant="gradient" color="error" onClick={() => setEditPresentation(false)}>Cancel</SoftButton>
                          </Stack>
                        )
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                      branding
                    </SoftTypography>
                    <SoftBox >
                      <SoftTypography variant="h6" color={'primary'} fontWeight={"bold"} textGradient>Company Logo</SoftTypography>
                    </SoftBox>
                    <SoftBox p={1} pl={0}>
                      <img src={company?.photoUrl || company?.logo?.url} width={100} alt="company logo" />
                    </SoftBox>
                    {!dizabled && (
                      <>
                        {!editLogo ? (
                          <SoftButton variant="gradient" color="warning" onClick={() => setEditLogo(true)}>
                            Edit Logo
                          </SoftButton>
                        ) : (

                          <Stack >
                            <SoftDropzone url={`https://plejsapp-api.herokuapp.com/api/v1/companies/updatelogo/${company?.id}`} onFile={updateLogo} options={{ addRemoveLinks: true }} />
                            <SoftButton sizevariant="gradient" color="error" onClick={() => setEditLogo(false)}>Cancel</SoftButton>

                          </Stack>
                        )}
                      </>
                    )}
                  </Grid>

                </Grid>
              </Card>
              <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                  {translate('updatesSaved')}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Box>
  );
}
