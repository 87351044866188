/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import Joyride, { STATUS } from "react-joyride";
import { Card, Typography, Box, Button } from "@mui/material";
import { Close } from "@mui/icons-material";
import { keyframes } from '@emotion/react';

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";
import SoftButton from "../../components/SoftButton";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";
import SidenavCard from "./SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "../../softcontext";
import SidenavMain from "./SidenavMain";
import { useSelector } from "react-redux";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

// Add this keyframe animation
const waveAnimation = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

function Sidenav({ color, brandwidth, brand, brandName, routes, ...rest }) {
  const { demouse } = useSelector((state) => state.company);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];
  const [runTour, setRunTour] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Add useEffect to check if this is the first visit
  useEffect(() => {
    const hasSeenNavTour = localStorage.getItem('hasSeenNavTour');
    if (!hasSeenNavTour) {
      setRunTour(true);
      localStorage.setItem('hasSeenNavTour', 'true');
    }
  }, []);

  const isDemoMode = location.pathname.startsWith('/demo');
  const prefixDemoPath = (path) => {
    return isDemoMode && path ? `/demo${path}` : path;
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={prefixDemoPath(href)}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={prefixDemoPath(route)} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={prefixDemoPath(route) === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={key === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={prefixDemoPath(href)}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={key === itemName} />
          </Link>
        ) : (
          <NavLink to={prefixDemoPath(route)} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Generate tour steps from routes
  const generateSteps = () => {
    const steps = [
      {
        target: '.sidenav-logo',
        content: 'Welcome to Workplacer! This is your navigation menu that will guide you through the workplace transformation journey.',
        disableBeacon: true,
      }
    ];

    routes.forEach((route) => {
      if (route.type === "title") {
        steps.push({
          target: `.nav-section-${route.key}`,
          content: `${route.title} section: Here you'll find tools and features related to ${route.title.toLowerCase()}.`,
        });
      } else if (route.type === "direct") {
        steps.push({
          target: `.nav-item-${route.key}`,
          content: getRouteDescription(route),
        });
      }
    });

    return steps;
  };

  // Helper function to generate meaningful descriptions for each route
  const getRouteDescription = (route) => {
    const descriptions = {
      portfoliooverview: "Get a bird's eye view of your entire workplace portfolio.",
      "portfolioworkplace-strategy": "Define and manage your workplace strategy across all locations.",
      prospectprospect: "Start your workplace transformation journey by creating a new prospect.",
      surveysurvey: "Gather insights from your employees through customizable surveys.",
      journeyunderstand: "Analyze and understand your current workplace situation.",
      journeystrategize: "Develop strategies based on collected data and insights.",
      journeyprogram: "Create detailed programs for your workplace transformation.",
      journeysolution: "Explore and compare different workplace solutions.",
      journeymarketplacer: "Connect with marketplace providers for implementation.",
      // Add descriptions for other routes as needed
    };

    return descriptions[route.key] || `${route.name}: Navigate to ${route.name.toLowerCase()} section.`;
  };

  const handleJoyrideCallback = (data) => {
    const { status, index } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunTour(false);
    }

    setStepIndex(index);
  };

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, completed, name, icon, title, collapse, noCollapse, key, href, route, isSwitch, isFuture, isNotForDemo }) => {
      let returnValue;
      if (demouse && isNotForDemo) return null;
      if (type === "collapse") {
        if (href) {
          returnValue = (
            <NavLink to={prefixDemoPath(href)} key={key}>
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                isSwitch={isSwitch}
                isFuture={isFuture}
                onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={prefixDemoPath(route)} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
                isSwitch={isSwitch}
                isFuture={isFuture}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              isSwitch={isSwitch}
              isFuture={isFuture}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "direct") {
        if (href) {
          if (isFuture) {
            returnValue = (
              <SidenavMain
                name={name}
                icon={icon}
                active={key === `${collapseName}${itemName}`}
                noCollapse={noCollapse}
                isSwitch={isSwitch}
                isFuture={isFuture}
                completed={completed}
              />)
          } else {
            returnValue = (
              <NavLink to={prefixDemoPath(href)} key={key}>
                <SidenavMain
                  name={name}
                  icon={icon}
                  active={key === `${collapseName}${itemName}`}
                  noCollapse={noCollapse}
                  isSwitch={isSwitch}
                  isFuture={isFuture}
                  completed={completed}
                />
              </NavLink>
            );
          }
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={prefixDemoPath(route)} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <SoftTypography
            key={key}
            className={`nav-section-${key}`}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            opacity={0.6}
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </SoftTypography>
        );
      } else if (type === "divider") {
        returnValue = <Divider key={key} />;
      }

      return returnValue;
    }
  );

  // Modify the CustomTooltip component to position on the right
  const CustomTooltip = ({
    continuous,
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    size,
    isLastStep,
  }) => (
    <Card
      elevation={4}
      sx={{
        position: 'fixed',
        top: 75,
        left: '280px',
        width: 'auto',
        maxWidth: '400px',
        minWidth: '320px',
        p: 5,
        pt: 8,
        backgroundColor: 'white',
        borderRadius: '12px',
        zIndex: 10001,
        transition: 'all 0.3s ease',
        boxShadow: '0 8px 32px rgba(0,0,0,0.1)',
        overflow: 'visible',
        ml: 4,
        transform: 'none',
      }}
    >
      {skipProps && (
        <SoftButton
          {...skipProps}
          variant="text"
          size="small"
          color="primary"
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            minWidth: 'auto',
          }}
        >
          <Close />
        </SoftButton>
      )}

      <Typography
        variant="caption"
        sx={{
          position: 'absolute',
          top: 20,
          left: 20,
          color: 'text.secondary',
        }}
      >
        {`${index + 1}/${size}`}
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          top: -60,
          left: 30,
          width: 90,
          height: 90,
          backgroundImage: 'url(/static/new/help.webp)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          zIndex: 10002,
          transformOrigin: 'bottom center',
          animation: (index === 0 || isLastStep) ? `${waveAnimation} 1.5s ease-in-out` : 'none',
        }}
      />

      <Typography 
        variant="body1" 
        sx={{ 
          mb: 4,
          lineHeight: 1.6,
          color: 'text.primary',
          fontSize: '1rem',
        }}
      >
        {step.content}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {index > 0 && (
          <SoftButton {...backProps} variant="outlined" color="primary">
            Back
          </SoftButton>
        )}
        <SoftButton {...primaryProps} variant="gradient" color="primary" sx={{ ml: 'auto' }}>
          {isLastStep ? 'Done' : 'Next'}
        </SoftButton>
      </Box>
    </Card>
  );

  return (
    <>
      <Joyride
        steps={generateSteps()}
        run={runTour}
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: 'transparent',
            backgroundColor: 'transparent',
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10000,
          },
        }}
        tooltipComponent={CustomTooltip}
        floaterProps={{
          disableAnimation: true,
        }}
      />
      
      <SidenavRoot {...rest} variant="permanent" ownerState={{ transparentSidenav, miniSidenav }}>
        <SoftBox pt={3} pb={1} px={4} textAlign="center" className="sidenav-logo">
          <SoftBox
            display={{ xs: "block", xl: "none" }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <SoftTypography variant="h6" color="secondary">
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </SoftTypography>
          </SoftBox>
          <SoftBox component={NavLink} to={prefixDemoPath("/")} display="flex" alignItems="center">
            {/*   {brand && <SoftBox component="img" src={brand} alt="Workplacer.ai Logo" width="2rem" />}
            
            <SoftBox
              width={!brandName && "100%"}
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            >
              <SoftTypography component="h6" variant="button" fontWeight="medium">
                {brandName}
              </SoftTypography>
            </SoftBox> */}

            <SoftBox component="img" src={brandwidth} alt="Workplacer.ai Logo" width="120%" />
          </SoftBox>
        </SoftBox>
        <Divider />
        <List>{renderRoutes}</List>

        <SoftBox p={2} mt="auto">
          <SoftButton
            variant="gradient"
            color="primary"
            fullWidth
            onClick={() => setRunTour(true)}
            startIcon={<HelpOutlineIcon />}
          >
            How it works
          </SoftButton>
        </SoftBox>
      </SidenavRoot>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
