import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { useTheme } from '@mui/material/styles';
import 'mapbox-gl/dist/mapbox-gl.css';
import { mapConfig } from '../../../config';
const GeoJSON = require('geojson');

mapboxgl.accessToken = mapConfig;
const MapboxMap = ({ coords, mapCh, origins }) => {
  const map = useRef(null);
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [indx, setIndx] = useState(0);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    if (!map.current || !map.current.getSource('origins') || !origins || !origins[0]) return;
    const points = GeoJSON.parse(origins, { Point: ['lat', 'lng'], include: ['durationValue'] });
    map.current.getSource('origins').setData(points);
    if (!coords || coords[0] === 0.0 ) return;
    let lineArr = [];
    const linesz = origins.map((o) => {
      const lineCoords = { geo: { type: 'LineString', coordinates: [coords, [o.lng, o.lat]] }, ...o };
      return lineCoords;
    })

    const lines = GeoJSON.parse(linesz, { GeoJSON: 'geo', include: ['durationValue'] });
    map.current.getSource('route').setData(lines);

  }, [origins, coords]);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: 'map', // container id
      style: mapCh || 'mapbox://styles/cronsioejesper/cl4o5u8v4005i14mqjupqopye',
      center: [18.0, 59.0],
      zoom: 6
    });
  });

  useEffect(() => {
    if (!map.current) return; // wait for map to initialize
    map.current.on('load', () => {
      // When the map loads, add the source and layer
      if (!map.current.getSource('origins')) {
        map.current.addSource('origins', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        });
      }
      map.current.addSource('route', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      map.current.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': {
            property: 'durationValue',
            stops: [
              [0, '#008f00'],
              [20, '#30D5C8'],
              [30, '#FFFF00'],
              [60, '#FFD700'],
              [120, '#8b0000']
            ]
          },
          'line-width': 3
        }
      });

      // When the map loads, add the source and layer
      if (!map.current.getLayer('origins')) {
        map.current.addLayer({
          id: 'origins',
          type: 'circle',
          source: 'origins',
          paint: {
            'circle-color': {
              property: 'durationValue',
              stops: [
                [0, '#008f00'],
                [20, '#30D5C8'],
                [30, '#FFFF00'],
                [60, '#FFD700'],
                [120, '#8b0000']
              ]
            },
            'circle-radius': 5,
            'circle-stroke-width': 4,
            'circle-stroke-opacity': 0.05
          }
        });
      }
    });
  });

  useEffect(() => {
    if (!coords || coords[0] === 0 || coords[1] === 0 || !map.current ) return;
    const lngLatt = { lon: coords[0], lat: coords[1] };
    marker.remove();
    if (markers.length > 0) {
      marker.setLngLat(lngLatt).addTo(map.current);
    }
    const markerz = markers;
    markerz.push(marker);
    setMarkers(markerz);
    map.current.flyTo({
      center: coords,
      zoom: 14
    });
  }, [coords, markers]);

  const marker = new mapboxgl.Marker({
    color: '#AF452B',
    draggable: true
  });

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div id="map" style={{ width: '100%', height: '100%' }} />
    </div>
  );
};

export default MapboxMap;
