import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { SnackbarProvider } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import 'react-internet-meter/dist/index.css';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import brandwidth from "./assets/images/logowidth.png";
import brand from "./assets/images/lohow.png";
import theme from "./assets/theme";
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import ScrollToTop from './components/ScrollToTop';
import { geoConfig, gridConfig } from './config';
import { CableContext, CableProvider } from './contexts/cable';
import Sidenav from "./examples/Sidenav";
import useAuth from './hooks/useAuth';
import NoInternetConnection from './NoInternet';
import { getAllCompanies, getCompanies, getCompany } from "./redux/slices/company";
import { getCompanyContracts, getCompanyJourneys, getJourney, getProspect, updateContract } from "./redux/slices/site";
import { getActivityprofiles, getGoaldomains, getJourneySpacebricks, getMeetings, getMobilityprofiles, getOfficefeatures, getWorkplacedistributions, getWorkplaces } from "./redux/slices/workplace";
import Router from './routes';
import routez from './routez';
import { setMiniSidenav, useSoftUIController } from "./softcontext";
import GlobalStyles from './theme/globalStyles';
// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);
LicenseInfo.setLicenseKey(gridConfig);

// ----------------------------------------------------------------------

function App() {
  const { isInitialized } = useAuth();
  const { user, login } = useAuth();
  const dispatchreact = useDispatch();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, layout, openConfigurator, sidenavColor } = controller;
  const { journey, contract, contracts, prospect, journeys } = useSelector((state) => state.site);
  const { workplaces, activityprofiles, spacebricks, workplacedistributions, mobilityprofiles, meetings, officefeatures, goaldomains } = useSelector((state) => state.workplace);
  const { company, demouse, companies } = useSelector((state) => state.company);

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [readyToLoad, setReadyToLoad] = useState(false);
  console.log('user', user);
  const location = useLocation();

  const hiddenSidenavRoutes = [
    '/login',
    '/signup',
    '/forgot-password',
    '/deepdive/:id',
    '/midpoint/:id',
    '/deepdive/:journey_id/:id',
    '/midpoint/:journey_id/:id',
    '/deepdive',
    '/midpoint',
  ];

  const shouldShowSidenav = !hiddenSidenavRoutes.some(route => {
    const routePattern = route
      .replace(/:[^\s/]+/g, '[^\\s/]+')
      .replace(/\//g, '\\/');

    const regex = new RegExp(`^${routePattern}$`);
    return regex.test(location.pathname);
  });

  useEffect(() => {
    if (!workplaces.length) {
      dispatchreact(getWorkplaces());
    }
  }, [dispatchreact, workplaces]);

  useEffect(() => {
    if (!goaldomains.length) {
      dispatchreact(getGoaldomains());
    }
  }, [dispatchreact, goaldomains]);

  useEffect(() => {
    if (!companies.length) {
      dispatchreact(getAllCompanies());
    }
  }, [dispatchreact, companies]);
  useEffect(() => {
    if (!activityprofiles.length) {
      dispatchreact(getActivityprofiles());
    }
  }, [dispatchreact, activityprofiles]);

  useEffect(() => {
    if (!journeys.length && user?.companyHubspot) {
      console.log('getting journeys');
      dispatchreact(getCompanyJourneys(user.companyHubspot));
    }
  }, [dispatchreact, journeys]);

  useEffect(() => {
    if (!workplacedistributions.length) {
      dispatchreact(getWorkplacedistributions());
    }
  }, [dispatchreact, workplacedistributions]);

  useEffect(() => {
    if (!meetings.length) {
      dispatchreact(getMeetings());
    }
  }, [dispatchreact, meetings]);

  useEffect(() => {
    if (!officefeatures.length) {
      dispatchreact(getOfficefeatures());
    }
  }, [dispatchreact, officefeatures]);

  useEffect(() => {
    if (!mobilityprofiles.length) {
      dispatchreact(getMobilityprofiles());
    }
  }, [dispatchreact, mobilityprofiles]);

  useEffect(() => {
    if (user.id && user?.companyHubspot && !company) {
      console.log('user found, getting journeys, sites and company data');
      dispatchreact(getCompany(user.companyHubspot));/* 
      dispatchreact(getDepartments(user.companyHubspot)); */
    }
  }, [user, dispatchreact]);

  useEffect(() => {

    if (demouse) {
      dispatchreact(getCompany(15578996295))
    }
  }, [demouse, dispatchreact]);

  useEffect(() => {
    if (company) {
      console.log('user found, getting journeys, sites and company data', company);
      dispatchreact(getCompanyContracts(company));
    }
  }, [company, dispatchreact]);

  useEffect(() => {
    if (!contracts.length) return;
    console.log('contracts', contracts);
    contracts.filter((c) => c.city && c.address && !c.latitude && !c.longitude).forEach(contract => {
      Geocode.fromAddress(`${contract.address}, ${contract.city}`).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          dispatchreact(updateContract(contract.id, { latitude: lat, longitude: lng }));
        },
        (error, response) => {
          console.log('error', contract, response);
          console.error(error);
        }
      );
    });
  }, [contracts]);


  useEffect(() => {
    if (contract?.journeys?.length) {
      dispatchreact(getJourney(contract.journeys[0].id));
    }
  }, [contract, dispatchreact]);

  /* useEffect(() => {
    if (mgmtSubmissions || !user.companyHubspot || !journey || !workplaces || !workplaces.length) return;
    setReadyToLoad(true);
  }, [dispatchreact, user, journey, mgmtSubmissions, workplaces]);
 */

  useEffect(() => {
    if (prospect || !user.companyHubspot || !contract.prospects?.length || !workplaces || !workplacedistributions.length || !activityprofiles.length) return;
    // setReadyToLoad(true);
    console.log('get initial prospect', contract.prospects[0].id);
    dispatchreact(getProspect(contract.prospects[0].id, workplaces, activityprofiles, workplacedistributions));
  }, [dispatchreact, user, contract.prospects, prospect, workplaces, workplacedistributions, activityprofiles]);
  /* 
    useEffect(() => {
      if (!readyToLoad || mgmtSubmissions) return;
      dispatchreact(getJourneyMgmtSubmissions(user.companyHubspot, journey.id, workplaces, journey.submission_id));
    }, [dispatchreact, readyToLoad, mgmtSubmissions]); */

  // Add this state to track company changes
  const [companyChangeTimestamp, setCompanyChangeTimestamp] = useState(null);

  // Add this effect to handle company changes and force data refresh


  // Pass this function down to DashboardNavbar
  const handleCompanyChange = () => {
    setCompanyChangeTimestamp(Date.now());
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const cableContext = useContext(CableContext);

  // console.log('channel', cableContext);
  useEffect(() => {
    // Add null check for user object
    if (!user || !user.id || !cableContext?.cable) return;

    const channel = cableContext.cable.subscriptions.create(
      {
        channel: "SurveyChannel",
        user_id: user.id
      },
      {
        connected: () => {
          console.log('connected');
        },
        received: (data) => {
          console.log('data received', data);
          if (data === 'reload' && user.companyHubspot) {
            dispatchreact(getCompanyJourneys(user.companyHubspot));
          }
        }
      });

    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [user, cableContext, dispatchreact]);

  return (
    <ThemeProvider theme={theme}>
      <CableProvider>
        <>
          <CssBaseline />
          <GlobalStyles />
          <ProgressBarStyle />
          <ScrollToTop />
          <SnackbarProvider />
          <NoInternetConnection>

            {isInitialized ? (
              <>
                {(user.id || demouse) && shouldShowSidenav && (
                  <>
                    <Sidenav
                      color={sidenavColor}
                      brand={brand}
                      brandwidth={brandwidth}
                      brandName="Workplacer.ai"
                      routes={routez}
                      onMouseEnter={handleOnMouseEnter}
                      onMouseLeave={handleOnMouseLeave}
                    />
                  </>
                )}
                <Router onCompanyChange={handleCompanyChange} />
              </>) : <LoadingScreen />}

          </NoInternetConnection>
        </>
      </CableProvider>
    </ThemeProvider>
  );
}

export default App;
