import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isEmpty: false,
  workplace: '',
  employees: 200,
  workplaceprofiles: [],
  workplaces: [],
  groupedworkplaces: [],
  activityprofiles: [],
  needbricks: [],
  spacebricks: [],
  generalareas: [],
  origins: [],
  goaldomains: [],
  hasLoadedOrigins: false,
  workplacedistributions: [],
  mobilityprofiles: [],
  meetings: [],
  officefeatures: [],
  sustainabilities: [],
  technicalities: [],
};

const slice = createSlice({
  name: 'workplace',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    hasLoadedOrigins(state) {
      state.hasLoadedOrigins = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET POST INFINITE
    getActivityprofilesSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      const newAProfiles = action.payload.map((a) => {
        const weightedShare = a.share * (a.officeshare - a.digitalleftover);
        const equivalents = weightedShare * state.employees;
        const newA = { ...a, weightedShare, equivalents };
        return newA;
      })
      console.log('newAprofiles,', newAProfiles);
      const modProfiles = newAProfiles.map((n) => {
        if (n.id !== 10) return n;
        const weightedLeftover = newAProfiles.reduce((a, v) => (a += Math.max(v.digitalleftover, 0) * v.share), 0);
        console.log('weightedleftover', weightedLeftover);
        const modN = { ...n, weightedShare: weightedLeftover };
        return modN;
      })
      const ratiosum = modProfiles.reduce((a, v) => (a += v.weightedShare), 0);
      const weightedmodprofiles = modProfiles.map((mp) => {
        const weightedShare = mp.weightedShare / ratiosum;
        const obj = { ...mp, weightedShare };
        return obj;
      })
      console.log('modprofiles,', weightedmodprofiles);
      state.activityprofiles = weightedmodprofiles;

    },
    // GET POST INFINITE
    getWorkplaceSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.workplace = action.payload;
    },
    getGeneralAreasSuccess(state, action) {
      state.isLoading = false;
      state.generalareas = action.payload;
    },
    getNeedbricksSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.needbricks = action.payload;
    },
    getSpacebricksSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.spacebricks = action.payload;
    },
    getOriginsSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.origins = action.payload;
    },
    // GET POST INFINITE
    getWorkplaceprofilesSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.workplaceprofiles = action.payload;
    },
    getWorkplacedistributionsSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.workplacedistributions = action.payload;
    },

    getSustainabilitiesSuccess(state, action) {
      state.isLoading = false;
      state.sustainabilities = action.payload;
    },

    getTechnicalitiesSuccess(state, action) {
      state.isLoading = false;
      state.technicalities = action.payload;
    },
    getMeetingsSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.meetings = action.payload;
    },
    getOfficefeaturesSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.officefeatures = action.payload;
    },
    getMobilityprofilesSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.mobilityprofiles = action.payload;
    },
    getGoaldomainsSuccess(state, action) {
      state.isLoading = false;
      state.goaldomains = action.payload;
    },
    // GET POST INFINITE
    getWorkplacesSuccess(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      const workplaces = action.payload;
      state.workplaces = workplaces;
      const soundzones = ['Room', 'Open', 'Private'];
      const groupedworkplaces = soundzones.map((s) => {
        const filteredplaces = workplaces.filter((w) => w.soundenv === s);
        const obj = { zone: s, workplaces: filteredplaces }
        return obj;
      })
      state.groupedworkplaces = groupedworkplaces
    },
    clearSpacebricks: (state) => {
      state.spacebricks = [];
    },
  }
});

// Reducer
export default slice.reducer;
// Actions
// ----------------------------------------------------------------------

export function getActivityprofiles() {
  console.log('aatvv');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/activityprofiles/index');
      console.log('avt,', response);
      dispatch(slice.actions.getActivityprofilesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGeneralAreas() {
  console.log('getting general areas');
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/generalareas/index');
      console.log('gen areas fetched,', response);
      dispatch(slice.actions.getGeneralAreasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateNeedbrick(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('uodate needbrick', params);
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/needbricks/update/${id}`, params);
      console.log('updated needbrick', response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplaces() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/workplaces/index`);
      dispatch(slice.actions.getWorkplacesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getNeedbricks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needbricks/index`);
      dispatch(slice.actions.getNeedbricksSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getSpacebricks() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/spacebricks/index`);
      dispatch(slice.actions.getSpacebricksSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneySpacebricks(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting journey spacebricks');
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/spacebricks/journeyindex/${params.journey_id}`, params);
      dispatch(slice.actions.getSpacebricksSuccess(response.data));
      console.log('journey spacebricks fetched', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateSpacebrick(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('update spacebrick', params);
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/spacebricks/update/${id}`, params);
      console.log('updated spacebrick', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyOrigins(journey_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting journey origins');
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/origins/journeyindex/`, {
        params: { journey_id }
      });
      dispatch(slice.actions.getOriginsSuccess(response.data));
      dispatch(slice.actions.hasLoadedOrigins());
      console.log('journey origins fetched', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateOrigin(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('update origin', params);
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/origins/update/${id}`, params);
      console.log('updated origin', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getGoaldomains() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/goaldomains/index`);
      dispatch(slice.actions.getGoaldomainsSuccess(response.data));
      console.log('got goaldomains', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplacedistributions() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/workplacedistributions/index`);
      dispatch(slice.actions.getWorkplacedistributionsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplaceprofiles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/workplaceprofiles/index`);
      dispatch(slice.actions.getWorkplaceprofilesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/workplaces/workplace/${id}`, {
        params: { id }
      });
      dispatch(slice.actions.getWorkplaceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export function getMobilityprofiles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/mobilityprofiles/index`);
      dispatch(slice.actions.getMobilityprofilesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyNeedbricks(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting journey needbricks');
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needbricks/journeyindex/${params.journey_id}`, params);
      dispatch(slice.actions.getNeedbricksSuccess(response.data));
      console.log('journey needbricks fetched', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMeetings() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/meetings/index`);
      dispatch(slice.actions.getMeetingsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOfficefeatures() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/officefeatures/index`);
      dispatch(slice.actions.getOfficefeaturesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanySustainabilties(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting company sustainabilties');
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/sustainabilities/companyindex/${params.company_id}`, params);
      dispatch(slice.actions.getSustainabilitiesSuccess(response.data));
      console.log('company sustainabilties fetched', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneySustainabilties(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting journey sustainabilties');
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/sustainabilities/journeyindex/${params.journey_id}`, params);
      dispatch(slice.actions.getSustainabilitiesSuccess(response.data));
      console.log('journey sustainabilties fetched', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyTechnicalities(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      console.log('getting journey technicalities');
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/technicalities/journeyindex/${params.journey_id}`, params);
      dispatch(slice.actions.getTechnicalitiesSuccess(response.data));
      console.log('journey technicalities fetched', response.data);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}