import Chip from '@mui/material/Chip';
import * as React from 'react';

import { ArrowDownward, ArrowForward, ArrowUpward } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import SoftTypography from '../../../components/SoftTypography';

export const STATUS_OPTIONS = ['None', 'Prospect', 'Journey', 'Completed', 'Rejected'];

const StyledChip = styled(Chip)(({ theme }) => ({
  justifyContent: 'left',
  '& .icon': {
    color: 'inherit',
  },
  '&.Prospect': {
    color: (theme.vars || theme).palette.info.dark,
    border: `1px solid ${(theme.vars || theme).palette.info.main}`,
  },
  '&.Completed': {
    color: (theme.vars || theme).palette.success.dark,
    border: `1px solid ${(theme.vars || theme).palette.success.main}`,
  },
  '&.Journey': {
    color: (theme.vars || theme).palette.warning.dark,
    border: `1px solid ${(theme.vars || theme).palette.warning.main}`,
  },
  '&.Rejected': {
    color: (theme.vars || theme).palette.error.dark,
    border: `1px solid ${(theme.vars || theme).palette.error.main}`,
  },
}));

const Status = React.memo((props) => {
  const { sqmNeed } = props;

  let icon = null;
  if (sqmNeed > 20) {
    icon = <ArrowUpward className="icon" color='success' />;
  } else if (sqmNeed > 0) {
    icon = <ArrowForward className="icon" color='warning' />;
  } else {
    icon = <ArrowDownward className="icon" color="error" />;
  }

  let label = sqmNeed;

  return (
    <Stack direction="row" spacing={1} sx={{ height: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
      {icon}
      <SoftTypography variant="body2">{label}</SoftTypography>
    </Stack>
  );
});


export function renderPotentialStatus(params) {
  if (params.value == null) {
    return '';
  }

  return <Status sqmNeed={params.value} />;
}

