import { ArrowDownward, ArrowUpward, CalendarMonth, ChevronRight, DeleteOutline, ExpandMore, Insights, NewReleases, Notifications, School, Search, Timeline, TipsAndUpdates } from '@mui/icons-material';
import { TreeItem, TreeView } from '@mui/lab';
import { Box, Card, Dialog, Divider, Grid, Icon, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { GridActionsCellItem, LicenseInfo } from '@mui/x-data-grid-pro';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SoftBox from '../../../components/SoftBox/index.js';
import SoftButton from '../../../components/SoftButton/index.js';
import SoftProgress from '../../../components/SoftProgress/index.js';
import SoftSnackbar from '../../../components/SoftSnackbar/index.js';
import SoftTypography from '../../../components/SoftTypography/index.js';
import { gridConfig } from '../../../config.js';
import FloatCounterCard from '../../../examples/Cards/CounterCards/FloatCounterCard/index.js';
import ThinCounterCard from '../../../examples/Cards/CounterCards/ThinCounterCard/index.js';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout/index.js';
import useAuth from '../../../hooks/useAuth';
import { updateCompany } from '../../../redux/slices/company.js';
import { getCompanyContracts, getContract, updateContract } from '../../../redux/slices/site.js';
import { dispatch } from '../../../redux/store.js';
import BatchContracts from './BatchContracts.js';
import { renderDeltaStatus } from './deltaCell.js';
import { renderPotentialStatus } from './potentialCell.js';
import { renderEditStatus, renderStatus, STATUS_OPTIONS } from './statusCell.js';

LicenseInfo.setLicenseKey(gridConfig);

// Add this content mapping object
const contentMap = {
  'launchpad': {
    breadcrumb: ['Launchpad'],
    title: 'Launchpad',
    description: 'Start your workplace transformation journey here.',
    videoUrl: 'https://example.com/tutorials/launchpad',
    faq: [
      {
        question: 'What is the Launchpad?',
        answer: 'The Launchpad is the starting point for your journey.'
      }
    ]
  },
  'strategy': {
    breadcrumb: ['Launchpad', 'Strategy'],
    title: 'Strategy Development',
    description: 'Define your workplace strategy aligned with organizational goals.',
    videoUrl: 'https://example.com/tutorials/strategy',
    faq: [
      {
        question: 'What elements should a workplace strategy include?',
        answer: 'A comprehensive workplace strategy should include business objectives, workforce needs, space requirements, and technology considerations.'
      }
    ]
  },
  'drivers': {
    breadcrumb: ['Prospect', 'Drivers'],
    title: 'Drivers',
    description: 'Identify key drivers for workplace change.',
    videoUrl: 'https://example.com/tutorials/drivers',
    faq: [
      {
        question: 'What are workplace drivers?',
        answer: 'Workplace drivers are factors that influence the need for change, such as technology, employee expectations, and business goals.'
      }
    ]
  },
  'way-of-working': {
    breadcrumb: ['Prospect', 'Way of Working'],
    title: 'Way of Working',
    description: 'Explore different working styles and their impact on productivity.',
    videoUrl: 'https://example.com/tutorials/way-of-working',
    faq: [
      {
        question: 'How does the way of working affect the workplace?',
        answer: 'The way of working influences space design, technology needs, and employee satisfaction.'
      }
    ]
  },
  'ambition-level': {
    breadcrumb: ['Prospect', 'Ambition Level'],
    title: 'Ambition Level',
    description: 'Determine the level of ambition for your workplace transformation.',
    videoUrl: 'https://example.com/tutorials/ambition-level',
    faq: [
      {
        question: 'What is an ambition level?',
        answer: 'An ambition level defines the scope and scale of workplace changes you aim to achieve.'
      }
    ]
  },
  'prospect': {
    breadcrumb: ['Prospect'],
    title: 'Prospect',
    description: 'Explore potential opportunities and strategies.',
    videoUrl: 'https://example.com/tutorials/prospect',
    faq: [
      {
        question: 'How do I identify prospects?',
        answer: 'Identify prospects by analyzing market trends and needs.'
      }
    ]
  },
  'survey': {
    breadcrumb: ['Survey'],
    title: 'Survey',
    description: 'Gather insights through comprehensive surveys.',
    videoUrl: 'https://example.com/tutorials/survey',
    faq: [
      {
        question: 'What is the purpose of the survey?',
        answer: 'The survey helps gather essential data for decision-making.'
      }
    ]
  },
  'understand': {
    breadcrumb: ['Understand'],
    title: 'Understand',
    description: 'Gain a deeper understanding of your workplace dynamics.',
    videoUrl: 'https://example.com/tutorials/understand',
    faq: [
      {
        question: 'Why is understanding important?',
        answer: 'Understanding helps tailor strategies to specific needs.'
      }
    ]
  },
  'strategize': {
    breadcrumb: ['Strategize'],
    title: 'Strategize',
    description: 'Develop effective strategies for workplace transformation.',
    videoUrl: 'https://example.com/tutorials/strategize',
    faq: [
      {
        question: 'How do I create a strategy?',
        answer: 'Create a strategy by aligning goals with resources.'
      }
    ]
  },
  'program': {
    breadcrumb: ['Program'],
    title: 'Program',
    description: 'Plan and implement your workplace program.',
    videoUrl: 'https://example.com/tutorials/program',
    faq: [
      {
        question: 'What is a workplace program?',
        answer: 'A workplace program outlines the steps for transformation.'
      }
    ]
  },
  'solution': {
    breadcrumb: ['Solution'],
    title: 'Solution',
    description: 'Implement solutions to achieve your workplace goals.',
    videoUrl: 'https://example.com/tutorials/solution',
    faq: [
      {
        question: 'What solutions are available?',
        answer: 'Solutions vary based on specific workplace needs.'
      }
    ]
  },
  'marketplacer': {
    breadcrumb: ['Marketplacer'],
    title: 'Marketplacer',
    description: 'Explore market opportunities and partnerships.',
    videoUrl: 'https://example.com/tutorials/marketplacer',
    faq: [
      {
        question: 'How do I engage with the market?',
        answer: 'Engage with the market through strategic partnerships.'
      }
    ]
  }
};

export default function WpHome() {
  const { user, login } = useAuth();
  const navigate = useNavigate();
  const { company, demouse } = useSelector((state) => state.company);
  const { journey, journeys, contracts, contract } = useSelector((state) => state.site);
  const [tabValue, setTabValue] = useState(0);
  const [openBatch, setOpenBatch] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [advancedView, setAdvancedView] = useState(false);
  const [completion, setCompletion] = useState(0);
  const [viewType, setViewType] = useState('potential');
  const [selectedNode, setSelectedNode] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  console.log('contract', contract, company);
  useEffect(() => {
    console.log('contracts', contracts);
  }, [contracts]);

  useEffect(() => {
    if (contract && contract.id) {
      setSelectionModel([contract.id]);
    }
  }, [contract]);
  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  }
  const handleProspect = (params) => {
    console.log('prospect', params);
    dispatch(getContract(params.row.id));
    console.log('contract is', contract);
    dispatch(updateCompany(company.id, { last_contract_id: params.row.id }));
    createProspect({ company_id: company.id, contract_id: params.row.id })
  }
  const handleOpenProspect = (params) => {
    console.log('open prospect', params);
    console.log('contract is', contract);
    dispatch(getContract(params.row.id));
    dispatch(updateCompany(company.id, { last_contract_id: params.row.id }));
    navigate(demouse ? '/demo/prospect/prospect' : '/prospect/prospect');
  }

  const handleJourneyAction = (params) => {
    console.log('journey action', params);
    Promise.all([
      dispatch(getContract(params.row.id)),
      dispatch(updateCompany(company.id, { last_contract_id: params.row.id }))
    ]).then(() => {
      switch (params.row.step) {
        case '1. Deep dive':
          navigate(demouse ? '/demo/journey/understand' : '/journey/understand');
          break;
        case '2. Strategy':
          navigate(demouse ? '/demo/journey/strategize' : '/journey/strategize');
          break;
        case '3. Program':
          navigate(demouse ? '/demo/journey/program' : '/journey/program');
          break;
        case '4. Search':
          navigate(demouse ? '/demo/journey/search' : '/journey/search');
          break;
        default:
          return;
      }
    });
  }

  const handleSelect = (params) => {
    console.log('selected', params);
    Promise.all([
      dispatch(getContract(params.row.id)),
      dispatch(updateCompany(company.id, { last_contract_id: params.row.id }))
    ]);
  }

  async function createProspect({ company_id, contract_id }) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/prospects/create`;
    try {
      await axios.post(url, {
        company_id,
        contract_id,
        sqm: contract.sqm,
        annual_rent: contract.annual_rent,
        employees: contract.employees_to,
        latitude: contract.latitude,
        longitude: contract.longitude,
        city: contract.city,
        postal_code: contract.postal_code,
        country: contract.country,
        work_model: contract.work_model,
        new_work_model: contract.work_model,
      })
        .then((response) => {
          console.log(response.data); // Assuming you want to log each successful response
          // dispatch(getCompanyContracts(company));
        })
        .then(() => {
          navigate(demouse ? '/demo/prospect/prospect/' : '/prospect/prospect/')
        })
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }

  async function createContract(company_id) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/contracts/create`;
    try {
      await axios.post(url, { company_id, is_active: true })
        .then((response) => {
          console.log(response.data); // Assuming you want to log each successful response
          dispatch(getCompanyContracts(company));
        })
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }


  function prospectStep(prospect) {
    if (prospect.rejected) return 'X. Rejected';
    if (prospect.postponed) return 'T. Postponed';
    if (prospect.isLocked) return '2. Decision';
    return '1. Scan';
  }
  function filterBasedOnTabValue(tabValue, journeys) {
    switch (tabValue) {
      case 0:
        return mapContracts;
      case 1:
        return mapContracts.filter((j) => j.prospects.length);
      case 2:
        return mapContracts.filter((j) => j.journeys.length);
      case 3:
        return mapContracts.filter((j) => j.journeys.length && j.journeys[0].isCompleted);
      default:
        return mapContracts;
    }
  }
  function colorFromPercentage(percentage) {
    if (percentage < 30) {
      return 'error';
    }
    if (percentage < 50) {
      return 'warning';
    }
    return 'success';
  };
  const paramsToStep = (j) => {
    if (j.isCompleted) return 'Y. Completed';
    if (j.hasProgram) return '4. Search';
    if (j.has_strategy) return '3. Program';
    if (j.hasClosedSurvey) return '2. Strategy';
    return '1. Deep dive';
  };
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  const prospectBenchMarkobj = contracts
    .filter((c) => (c.prospects.length && c.prospects[0].sqmNeed !== 0 && c.prospects[0].sqmNeed !== null))
    .map((j) => ({
      ...j,
      sqm: j.sqm,
      employees: j.employees_to,
      sqmNeed: j.prospects[0].sqmNeed,
      sqmDelta: j.sqm - j.prospects[0].sqmNeed,
      potential: (j.sqm - j.prospects[0].sqmNeed) * (j.annual_rent / j.sqm),
      sqmNeedEmpl: j.prospects[0]?.sqmNeed / j.employees_to,
      rentEmpl: j.annual_rent / j.employees_to
    }));
  console.log('prospectBenchMarkobj', prospectBenchMarkobj, prospectBenchMarkobj.length);
  const prospectBenchMark = prospectBenchMarkobj.reduce((acc, curr) => {
    return {
      sqmNeed: acc.sqmNeed + curr.sqmNeed, // sum of all sqmNeed
      sqmDelta: acc.sqmDelta + curr.sqmDelta, // sum of all sqmDelta
      potential: acc.potential + curr.potential, // sum of all savings
      sqmNeedEmpl: acc.sqmNeedEmpl + curr.sqmNeedEmpl, // sum of all sqmNeedEmpl

    };
  }, { sqm: 0, employees: 0, sqmNeed: 0, sqmDelta: 0, potential: 0, sqmEmpl: 0, rentEmpl: 0, sqmNeedEmpl: 0 });
  console.log('prospectBenchMark', prospectBenchMark);
  const benchMarkObj = contracts
    .map((j) => ({
      ...j,
      sqm: j.sqm,
      employees: j.employees_to,
      sqmNeed: j.prospects[0]?.sqmNeed,
      sqmDelta: j.sqm - j.prospects[0]?.sqmNeed,
      potential: (j.sqm - j.prospects[0]?.sqmNeed) * (j.annual_rent / j.sqm),
      sqmEmpl: j.sqm / j.employees_to,
      rentEmpl: j.annual_rent / j.employees_to
    }));
  const benchMark = benchMarkObj.reduce((acc, curr) => {
    return {
      sqm: acc.sqm + curr.sqm,  // sum of all sqm
      employees: acc.employees + curr.employees, // sum of all employees
      sqmEmpl: acc.sqmEmpl + curr.sqmEmpl, // sum of all sqmEmpl
      rentEmpl: acc.rentEmpl + curr.rentEmpl, // sum of all rentEmpl

    };
  }, { sqm: 0, employees: 0, sqmNeed: 0, sqmDelta: 0, potential: 0, sqmEmpl: 0, rentEmpl: 0 });
  const avgSqmEmpl = benchMark.sqmEmpl / benchMarkObj.length;
  const avgRentEmpl = benchMark.rentEmpl / benchMarkObj.length;
  const avgPotential = prospectBenchMark.potential / prospectBenchMarkobj.length;
  const avgSqmDelta = benchMark.sqmDelta / benchMarkObj.length;
  const avgSqmNeed = benchMark.sqmNeed / benchMarkObj.length;
  const avgSqmNeedEmpl = prospectBenchMark.sqmNeedEmpl / prospectBenchMarkobj.length;
  console.log('avgSqmNeedEmpl', avgSqmNeedEmpl);


  const mapContracts = contracts.map((j) => {
    return {
      ...j,
      id: j.id,
      label: j.name,
      status: j.journeys[0] ? 'Journey' : j.prospects[0] ? 'Prospect' : 'Site',
      step: j.journeys[0] ? paramsToStep(j.journeys[0]) : j.prospects[0] ? prospectStep(j.prospects[0]) : 'Site',
      sqm: j.sqm,
      annual_rent: j.annual_rent,
      employees: j.employees_to,
      sqmNeed: Math.round(j.prospects[0]?.sqmNeed || (j.employees_to * avgSqmNeedEmpl / 10) * 10),
      sqmDelta: -(Math.round(j.prospects[0]?.sqmNeed ? j.sqm - j.prospects[0]?.sqmNeed : j.sqm - (j.employees_to * avgSqmNeedEmpl))),
      potential: Math.round(j.prospects[0]?.sqmNeed ? (j.annual_rent / j.sqm) * (j.sqm - j.prospects[0]?.sqmNeed) : (j.annual_rent / j.sqm) * (j.sqm - (j.employees_to * avgSqmNeedEmpl))) || 0,
      potentialShare: Math.round(100 * (j.prospects[0]?.sqmNeed ? (j.annual_rent / j.sqm) * (j.sqm - j.prospects[0]?.sqmNeed) : (j.annual_rent / j.sqm) * (j.sqm - (j.employees_to * avgSqmNeedEmpl))) / j.annual_rent) || 0,
    };
  });

  const filteredJourneys = filterBasedOnTabValue(tabValue, mapContracts);
  const columns = [
    {
      field: 'select',
      headerName: '',
      width: 120,
      renderCell: (params) => {
        return (
          <SoftButton variant="gradient" color="success" onClick={() => handleSelect(params)}>
            Select
          </SoftButton>
        )
      }
    },
    { field: 'id', headerName: 'ID', width: 90, hide: true },
    {
      field: 'label',
      headerName: 'Label',
      width: 150,
      editable: true,
      preProcessEditCellProps: (params) => {
        dispatch(updateContract(params.row.id, { name: params.props.value }))
          .then(() => dispatch(getCompanyContracts(company)));
        return { ...params.props };
      }
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 200,
      editable: true,
      preProcessEditCellProps: (params) => {
        dispatch(updateContract(params.row.id, { address: params.props.value }))
          .then(() => dispatch(getCompanyContracts(company)));
        return { ...params.props };
      }
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      editable: true,
      preProcessEditCellProps: (params) => {
        dispatch(updateContract(params.row.id, { city: params.props.value }))
          .then(() => dispatch(getCompanyContracts(company)));
        return { ...params.props };
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: renderStatus,
      renderEditCell: renderEditStatus,
      type: 'singleSelect',
      valueOptions: STATUS_OPTIONS,
      width: 120,
      editable: true,
    },/* 
    { field: 'step', headerName: 'Step', width: 120 }, */
    {
      field: 'potential',
      headerName: 'Potential',
      type: 'number',
      width: 100,
    },
    {
      field: 'potentialShare',
      headerName: '%',
      renderCell: renderPotentialStatus,
      type: 'number',
      width: 100,
    },
    {
      field: 'sqm',
      headerName: 'Sqm today',
      type: 'number',
      width: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        dispatch(updateContract(params.row.id, { sqm: params.props.value }))
          .then(() => dispatch(getCompanyContracts(company)));
        return { ...params.props };
      }
    },

    {
      field: 'sqmNeed',
      headerName: 'Sqm need',
      type: 'number',
      width: 100,
    },
    {
      field: 'sqmDelta',
      renderCell: renderDeltaStatus,
      headerName: 'Right sizing sqm',
      type: 'number',
      width: 100,
    },
    {
      field: 'through',
      headerName: 'Lease end',
      type: 'date',
      width: 100,
      editable: true,
      preProcessEditCellProps: (params) => {
        dispatch(updateContract(params.row.id, { through: params.props.value }))
          .then(() => dispatch(getCompanyContracts(company)));
        return { ...params.props };
      }
    },
    {
      field: 'button',
      headerName: '',
      width: 150,
      renderCell: (params) => {
        if (params.row.journeys.length) {
          return (
            <SoftButton variant="gradient" color="success" onClick={() => handleJourneyAction(params)}>
              Resume Journey
            </SoftButton>
          )
        } else if (params.row.prospects.length) {
          return (
            <SoftButton variant="gradient" color="warning" onClick={() => handleOpenProspect(params)}>
              Resume Prospect
            </SoftButton>
          )
        } else if (!params.row.prospects.length) {
          return (
            <SoftButton variant="gradient" color="primary" onClick={() => handleProspect(params)}>
              Prospect Site
            </SoftButton>
          )
        }
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        if (demouse) return [];
        return [
          <GridActionsCellItem
            icon={<DeleteOutline />}
            label="Delete"
            onClick={() => handleDelete(id)}
            color="error"
          />,
        ];
      },
    },
  ];
  const handleCompleteBatch = () => {
    setOpenBatch(false);
    setShowWarning(true);
    setTimeout(() => {
      setShowWarning(false);
    }, 3000);
  }
  async function deleteContract(id) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/contracts/update/${id}`;
    try {
      const response = await axios.put(url, { is_active: false });
      console.log('deleted');
      dispatch(getCompanyContracts(company));
      setShowDelete(true);
      setTimeout(() => {
        setShowDelete(false);
      }, 3000);
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }
  const handleDelete = (id) => {
    console.log('delete', id);
    dispatch(deleteContract(id));
  }
  const handleViewTypeChange = (event, newValue) => {
    setViewType(newValue);
  };

  const WikiContent = ({ selectedNode }) => {
    const content = contentMap[selectedNode] || {
      breadcrumb: ['Select a topic'],
      title: 'Select a topic',
      description: 'Please select a topic from the navigation tree to view its content.',
      videoUrl: '',
      faq: []
    };

    return (
      <Box sx={{ p: 3 }}>
        {/* Breadcrumbs */}
        <Stack direction="row" spacing={1} alignItems="center" mb={3}>
          {content.breadcrumb.map((item, index) => (
            <React.Fragment key={index}>
              <SoftTypography
                variant="body2"
                color={index === content.breadcrumb.length - 1 ? 'primary.main' : 'text.secondary'}
                sx={{ fontWeight: index === content.breadcrumb.length - 1 ? 'bold' : 'regular' }}
              >
                {item}
              </SoftTypography>
              {index < content.breadcrumb.length - 1 && (
                <Icon fontSize="small" sx={{ color: 'text.secondary' }}>chevron_right</Icon>
              )}
            </React.Fragment>
          ))}
        </Stack>

        {/* Title */}
        <SoftTypography variant="h4" fontWeight="bold" mb={3}>
          {content.title}
        </SoftTypography>

        {/* Description */}
        <Card sx={{ p: 3, mb: 3 }}>
          <SoftTypography variant="body1">
            {content.description}
          </SoftTypography>
        </Card>

        {/* Video Tutorial */}
        {content.videoUrl && (
          <Card sx={{ p: 3, mb: 3 }}>
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
              <Icon color="primary">play_circle</Icon>
              <SoftTypography variant="h6">Video Tutorial</SoftTypography>
            </Stack>
            <Box sx={{
              position: 'relative',
              paddingTop: '56.25%', // 16:9 Aspect Ratio
              backgroundColor: 'grey.100',
              borderRadius: 1
            }}>
              {/* Video player component would go here */}
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <Icon sx={{ fontSize: 48, color: 'text.secondary' }}>play_circle_outline</Icon>
              </Box>
            </Box>
          </Card>
        )}

        {/* FAQ Section */}
        {content.faq.length > 0 && (
          <Card sx={{ p: 3 }}>
            <Stack direction="row" spacing={2} alignItems="center" mb={2}>
              <Icon color="primary">help_outline</Icon>
              <SoftTypography variant="h6">Frequently Asked Questions</SoftTypography>
            </Stack>
            <Stack spacing={2}>
              {content.faq.map((item, index) => (
                <Box key={index}>
                  <SoftTypography variant="subtitle1" fontWeight="medium" color="primary">
                    {item.question}
                  </SoftTypography>
                  <SoftTypography variant="body2" color="text.secondary">
                    {item.answer}
                  </SoftTypography>
                </Box>
              ))}
            </Stack>
          </Card>
        )}
      </Box>
    );
  };

  const shouldShowNode = (nodeLabel) => {
    if (!searchQuery) return true;
    return nodeLabel.toLowerCase().includes(searchQuery.toLowerCase());
  };

  return (
    <>
      <DashboardLayout>
        <SoftSnackbar
          color="error"
          icon="notifications"
          title="Site deleted"
          content="The site has been deleted from your portfolio."
          dateTime="now"
          duration={3000}
          open={showDelete}
          close={() => setShowDelete(false)}
        />
        <SoftSnackbar
          color="success"
          icon="notifications"
          title="Sites uploaded"
          content="You have added new sites to your portfolio. You can now start onboarding them."
          dateTime="now"
          duration={3000}
          open={showWarning}
          close={() => setShowWarning(false)}
        />
        <Dialog sx={{ p: 3 }} open={openBatch} onClose={() => setOpenBatch(false)} fullWidth>
          <BatchContracts onComplete={handleCompleteBatch} />
        </Dialog>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                    <Insights />
                    <SoftTypography variant="h3" fontWeight="bold" textGradient color="primary">
                      Insights
                    </SoftTypography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <FloatCounterCard
                    count={mapContracts.length ? Math.round(mapContracts.reduce((acc, curr) => acc + curr.potential, 0) * 10 / 1000000) / 10 : 0}
                    prefix={<>m€</>}
                    color="success"
                    title="Total Savings Potential"
                    description="Across all sites"
                    icon={<ArrowUpward />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FloatCounterCard
                    count={mapContracts.filter(c => c.sqmDelta < 0).length}
                    color="info"
                    title="Sites to Optimize"
                    description="Ready for right-sizing"
                    icon={<Timeline />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FloatCounterCard
                    count={Math.round(mapContracts.reduce((acc, curr) => acc + curr.sqmDelta, 0))}
                    suffix=" sqm"
                    color="warning"
                    title="Total Space Delta"
                    description="Potential reduction"
                    icon={<ArrowDownward />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FloatCounterCard
                    count={mapContracts.length}
                    color="primary"
                    title="Total Sites"
                    description="In portfolio"
                    icon={<CalendarMonth />}
                  />
                </Grid>
              </Grid>
            </Grid>
            <>


              {completion === 100 ? null : (
                <Grid item xs={completion === 100 ? 0 : 6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <SoftTypography variant="h3" fontWeight="bold" textGradient color='primary'>
                        Onboarding
                      </SoftTypography>
                    </Grid>
                    <Grid item xs={12}>

                      <ThinCounterCard
                        count={completion}
                        suffix={<>% completed</>}
                        color={colorFromPercentage(completion)}
                        primary="Onboarding completion"
                      />
                      <SoftProgress value={completion} color="primary" variant="gradient" />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <SoftTypography variant="h6" fontWeight="bold" textGradient color='primary'>
                          Portfolio uploaded
                        </SoftTypography>
                        <SoftBox display="flex" flexDirection="column">
                          <IconButton onClick={() => console.log('click')}>
                            {!journey || !journey.has_goals ? <Icon color="warning">warning</Icon> : <Icon color={journey.has_goals ? "success" : 'inherit'}>check</Icon>}
                          </IconButton>
                        </SoftBox>
                      </Stack>

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <SoftTypography variant="h6" fontWeight="bold" textGradient color='primary'>
                          Global strategy completed
                        </SoftTypography>
                        <SoftBox display="flex" flexDirection="column">
                          <IconButton onClick={() => console.log('click')}>
                            {!journey || !journey.has_goals ? <Icon color="warning">warning</Icon> : <Icon color={journey.has_goals ? "success" : 'inherit'}>check</Icon>}
                          </IconButton>
                        </SoftBox>
                      </Stack>

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <SoftTypography variant="h6" fontWeight="bold" textGradient color='primary'>
                          First prospect evaluated
                        </SoftTypography>
                        <SoftBox display="flex" flexDirection="column">
                          <IconButton onClick={() => console.log('click')}>
                            {!contract || !contract.prospects.length ? <Icon color="error">close</Icon> : <Icon color={journey.has_prospect ? "success" : 'inherit'}>check</Icon>}
                          </IconButton>
                        </SoftBox>
                      </Stack>

                      <Stack direction="row" spacing={1} sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <SoftTypography variant="h6" fontWeight="bold" textGradient color='primary'>
                          First journey completed
                        </SoftTypography>
                        <SoftBox display="flex" flexDirection="column">
                          <IconButton onClick={() => console.log('click')}>
                            {!journey || !journey.has_compl ? <Icon color="error">close</Icon> : <Icon color={journey.has_compl ? "success" : 'inherit'}>check</Icon>}
                          </IconButton>
                        </SoftBox>
                      </Stack>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </>
          </Grid>
        </SoftBox>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', p: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <TipsAndUpdates color="warning" />
                <SoftTypography variant="h4" fontWeight="bold">
                  Recommendations
                </SoftTypography>
              </Stack>
              <Stack spacing={2}>
                {mapContracts.slice(0, 3).map((contract, index) => (
                  <Card key={index} sx={{ p: 1, backgroundColor: 'grey.100' }}>
                    <SoftTypography variant="body2">
                      Consider optimizing {contract.name} for potential savings of {Math.round(contract.potential / 1000)}k€
                    </SoftTypography>
                  </Card>
                ))}
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', p: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <NewReleases color="info" />
                <SoftTypography variant="h4" fontWeight="bold">
                  Latest Updates
                </SoftTypography>
              </Stack>
              <Stack spacing={2}>
                <Card sx={{ p: 1, backgroundColor: 'grey.100' }}>
                  <SoftTypography variant="body2">
                    New optimization algorithm released for space planning
                  </SoftTypography>
                </Card>
                <Card sx={{ p: 1, backgroundColor: 'grey.100' }}>
                  <SoftTypography variant="body2">
                    Enhanced reporting features now available
                  </SoftTypography>
                </Card>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', p: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <School color="primary" />
                <SoftTypography variant="h4" fontWeight="bold">
                  Getting Started
                </SoftTypography>
              </Stack>
              <Stack spacing={2}>
                <SoftButton variant="outlined" color="primary" fullWidth>
                  Watch Platform Tutorial
                </SoftButton>
                <SoftButton variant="outlined" color="info" fullWidth>
                  Schedule Demo Call
                </SoftButton>
              </Stack>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card sx={{ height: '100%', p: 2 }}>
              <Stack direction="row" spacing={2} alignItems="center" mb={2}>
                <Notifications color="warning" />
                <SoftTypography variant="h4" fontWeight="bold">
                  Notifications
                </SoftTypography>
              </Stack>
              <Stack spacing={2}>
                {completion < 100 && (
                  <Card sx={{ p: 1, backgroundColor: 'grey.100' }}>
                    <SoftTypography variant="body2">
                      Complete your onboarding to unlock all features
                    </SoftTypography>
                  </Card>
                )}
                <Card sx={{ p: 1, backgroundColor: 'grey.100' }}>
                  <SoftTypography variant="body2">
                    {mapContracts.length} sites ready for optimization
                  </SoftTypography>
                </Card>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>

            <Card
              sx={{
                mb: 3,
                overflow: 'hidden',
                position: 'relative',
                '&:hover .overlay': {
                  opacity: 1
                }
              }}
            >
              {/* Dark gradient overlay */}
              <Box
                className="overlay"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: 'linear-gradient(180deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 100%)',
                  opacity: 0.6,
                  transition: 'opacity 0.3s ease',
                  zIndex: 1
                }}
              />

              {/* Content overlay */}
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  p: 4,
                  zIndex: 2
                }}
              >
                <SoftTypography
                  variant="h4"
                  fontWeight="bold"
                  color="white"
                  mb={1}
                >
                  Workplace Journey Map
                </SoftTypography>
                <SoftTypography
                  variant="body1"
                  color="white"
                  sx={{ maxWidth: '60%' }}
                >
                  Navigate through the complete workplace transformation process, from initial assessment to final implementation
                </SoftTypography>
              </Box>

              {/* Full-width image */}
              <Box
                component="img"
                src="/static/journey-map.png"
                alt="Workplace Journey Map"
                sx={{
                  width: '100%',
                  height: '500px',
                  objectFit: 'cover',
                  display: 'block'
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <SoftTypography variant="h3" fontWeight="bold" textGradient color='primary'>
              Wiki
            </SoftTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <Card sx={{ height: '100%', p: 2 }}>
                  <SoftTypography variant="h6" fontWeight="medium" mb={2}>
                    Navigation
                  </SoftTypography>
                  <TextField
                    fullWidth
                    size="small"
                    placeholder="Search wiki..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    sx={{ mb: 2 }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TreeView
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    sx={{
                      '& .MuiTreeItem-root': {
                        '& .MuiTreeItem-content': {
                          p: 0.5,
                          borderRadius: 1,
                          '&:hover': {
                            bgcolor: 'rgba(0,0,0,0.04)'
                          }
                        }
                      }
                    }}
                    onNodeSelect={(event, nodeId) => setSelectedNode(nodeId)}
                  >
                    {shouldShowNode('Launchpad') && (
                      <TreeItem nodeId="launchpad" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">rocket_launch</Icon>
                          <span>Overall</span>
                        </Stack>
                      }>
                        {shouldShowNode('Portfolio') && <TreeItem nodeId="portfolio" label="Portfolio" />}
                        {shouldShowNode('Contract Management') && <TreeItem nodeId="contract-management" label="Contract Management" />}
                        {shouldShowNode('Global Workplace Strategy') && <TreeItem nodeId="global-workplace-strategy" label="Global Workplace Strategy" />}
                        {shouldShowNode('Company Profile') && <TreeItem nodeId="company-profile" label="Company Profile" />}

                      </TreeItem>
                    )}

                    {shouldShowNode('Prospect') && (
                      <TreeItem nodeId="prospect" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">search</Icon>
                          <span>Prospect</span>
                        </Stack>
                      }>
                        {shouldShowNode('Drivers') && <TreeItem nodeId="drivers" label="Drivers" />}
                        {shouldShowNode('Way of Working') && <TreeItem nodeId="way-of-working" label="Way of Working" />}
                        {shouldShowNode('Ambition Level') && <TreeItem nodeId="ambition-level" label="Ambition Level" />}
                        {shouldShowNode('Potential') && <TreeItem nodeId="potential" label="Potential" />}
                        {shouldShowNode('Costs') && <TreeItem nodeId="costs" label="Costs" />}
                        {shouldShowNode('Employee Needs') && <TreeItem nodeId="employee-needs" label="Employee Needs" sx={{ color: 'primary.main', fontWeight: 'bold' }} />}
                      </TreeItem>
                    )}

                    {shouldShowNode('Survey') && (
                      <TreeItem nodeId="survey" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">poll</Icon>
                          <span>Survey</span>
                        </Stack>
                      }>
                        {shouldShowNode('Workpl. Dist.') && <TreeItem nodeId="workpl-dist" label="Workpl. Dist." />}
                        {shouldShowNode('Mobility') && <TreeItem nodeId="mobility" label="Mobility" />}
                        {shouldShowNode('Activities') && <TreeItem nodeId="activities" label="Activities" />}
                        {shouldShowNode('Activity Dist.') && <TreeItem nodeId="activity-dist" label="Activity Dist." />}
                        {shouldShowNode('Sound Env.') && <TreeItem nodeId="sound-env" label="Sound Env." />}
                        {shouldShowNode('Relations') && <TreeItem nodeId="relations" label="Relations" />}
                        {shouldShowNode('Features') && <TreeItem nodeId="features" label="Features" />}
                        {shouldShowNode('Employee Needs') && <TreeItem nodeId="employee-needs" label="Employee Needs" sx={{ color: 'primary.main', fontWeight: 'bold' }} />}
                      </TreeItem>
                    )}

                    {shouldShowNode('Understand') && (
                      <TreeItem nodeId="understand" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">psychology</Icon>
                          <span>Understand</span>
                        </Stack>
                      }>
                        {shouldShowNode('Survey Insights') && <TreeItem nodeId="survey-insights" label="Survey Insights" />}
                        {shouldShowNode('Activity Matrix') && <TreeItem nodeId="activity-matrix" label="Activity Matrix" />}
                        {shouldShowNode('Relationships') && <TreeItem nodeId="relationships" label="Relationships" />}
                        {shouldShowNode('Personas') && <TreeItem nodeId="personas" label="Personas" />}
                        {shouldShowNode('State of Work') && <TreeItem nodeId="state-of-work" label="State of Work" sx={{ color: 'primary.main', fontWeight: 'bold' }} />}
                      </TreeItem>
                    )}

                    {shouldShowNode('Strategize') && (
                      <TreeItem nodeId="strategize" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">lightbulb</Icon>
                          <span>Strategize</span>
                        </Stack>
                      }>
                        {shouldShowNode('Growth') && <TreeItem nodeId="growth" label="Growth" />}
                        {shouldShowNode('Goals') && <TreeItem nodeId="goals" label="Goals" />}
                        {shouldShowNode('Work Model') && <TreeItem nodeId="work-model" label="Work Model" />}
                        {shouldShowNode('Dimensioning') && <TreeItem nodeId="dimensioning" label="Dimensioning" />}
                        {shouldShowNode('Budget') && <TreeItem nodeId="budget" label="Budget" />}
                        {shouldShowNode('Location') && <TreeItem nodeId="location" label="Location" />}
                        {shouldShowNode('Workplace Strategy') && <TreeItem nodeId="workplace-strategy" label="Workplace Strategy" sx={{ color: 'primary.main', fontWeight: 'bold' }} />}
                      </TreeItem>
                    )}

                    {shouldShowNode('Program') && (
                      <TreeItem nodeId="program" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">architecture</Icon>
                          <span>Program</span>
                        </Stack>
                      }>
                        {shouldShowNode('Work Settings') && <TreeItem nodeId="work-settings" label="Work Settings" />}
                        {shouldShowNode('Support Areas') && <TreeItem nodeId="support-areas" label="Support Areas" />}
                        {shouldShowNode('Business Areas') && <TreeItem nodeId="business-areas" label="Business Areas" />}
                        {shouldShowNode('Blueprint') && <TreeItem nodeId="blueprint" label="Blueprint" />}
                        {shouldShowNode('Technical Req.') && <TreeItem nodeId="technical-req" label="Technical Req." />}
                        {shouldShowNode('Sustainability') && <TreeItem nodeId="sustainability" label="Sustainability" />}
                        {shouldShowNode('Premises Program') && <TreeItem nodeId="premises-program" label="Premises Program" sx={{ color: 'primary.main', fontWeight: 'bold' }} />}
                      </TreeItem>
                    )}

                    {shouldShowNode('Solution') && (
                      <TreeItem nodeId="solution" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">apartment</Icon>
                          <span>Solution</span>
                        </Stack>
                      }>
                        {shouldShowNode('Current') && <TreeItem nodeId="current" label="Current" />}
                        {shouldShowNode('Goal Evaluation') && <TreeItem nodeId="goal-evaluation" label="Goal Evaluation" />}
                        {shouldShowNode('Req. Evaluation') && <TreeItem nodeId="req-evaluation" label="Req. Evaluation" />}
                        {shouldShowNode('Adaptations') && <TreeItem nodeId="adaptations" label="Adaptations" />}
                        {shouldShowNode('Solution Strategy') && <TreeItem nodeId="solution-strategy" label="Solution Strategy" sx={{ color: 'primary.main', fontWeight: 'bold' }} />}
                      </TreeItem>
                    )}

                    {shouldShowNode('Marketplacer') && (
                      <TreeItem nodeId="marketplacer" label={
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Icon fontSize="small">store</Icon>
                          <span>Marketplacer</span>
                        </Stack>
                      }>
                        {shouldShowNode('Listings') && <TreeItem nodeId="listings" label="Listings" />}
                        {shouldShowNode('Proposals') && <TreeItem nodeId="proposals" label="Proposals" />}
                        {shouldShowNode('Inquiry') && <TreeItem nodeId="inquiry" label="Inquiry" />}
                        {shouldShowNode('Offers') && <TreeItem nodeId="offers" label="Offers" />}
                        {shouldShowNode('Contract') && <TreeItem nodeId="contract" label="Contract" sx={{ color: 'primary.main', fontWeight: 'bold' }} />}
                      </TreeItem>
                    )}
                  </TreeView>
                </Card>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <Card sx={{ height: '100%', minHeight: '600px' }}>
                  {!selectedNode ? (
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: 2,
                        p: 3
                      }}
                    >
                      <Icon sx={{ fontSize: 48, color: 'text.secondary' }}>menu_book</Icon>
                      <SoftTypography variant="h5" color="text.secondary">
                        Select a topic from the navigation tree
                      </SoftTypography>
                    </Box>
                  ) : (
                    <WikiContent selectedNode={selectedNode} />
                  )}
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DashboardLayout>
    </>
  );
}
