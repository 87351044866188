/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import { Badge, Box, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import SoftBox from "../../../components/SoftBox";
import SoftSelect from "../../../components/SoftSelect";
import SoftTypography from "../../../components/SoftTypography";
import useAuth from "../../../hooks/useAuth";
import useOffSetTop from '../../../hooks/useOffSetTop';
import AccountPopover from "../../../layouts/dashboard/AccountPopover";
import {
  setMiniSidenav,
  setOpenConfigurator, setTransparentNavbar, useSoftUIController
} from "../../../softcontext";
import NotificationItem from "../../Items/NotificationItem";
import {
  navbar,
  navbarContainer, navbarDesktopMenu, navbarRow
} from "./styles";
import { getCompany } from "../../../redux/slices/company";
import axios from "axios";
import { getJourneySpacebricks } from "../../../redux/slices/workplace";
function DashboardNavbar({ absolute, light, isMini, name, onCompanyChange }) {
  const { user, login, logout } = useAuth();
  const dispatchredux = useDispatch();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { company, companies } = useSelector((state) => state.company);
  const { journey, contract, contracts } = useSelector((state) => state.site);
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const navigate = useNavigate();

  // Add this mapping for steps
  const pathToStep = {
    'prospect': 0,
    'survey': 1,
    'understand': 2,
    'strategize': 3,
    'program': 4,
    'solution': 5
  };

  // Get the current step based on the path
  const currentStep = pathToStep[route[route.length - 1]] || 0;

  // Reverse mapping for navigation
  const stepToPath = {
    0: 'prospect/prospect',
    1: 'survey/survey',
    2: 'journey/understand',
    3: 'journey/strategize',
    4: 'journey/program',
    5: 'journey/solution'
  };

  const handleStepClick = (index) => {
    const path = stepToPath[index];
    if (path) {
      navigate(`/${path}`);
    }
  };

  useEffect(() => {
    // Setting the navbar type
    /*   if (fixedNavbar) {
        setNavbarType("sticky");
      } else {
        setNavbarType("static");
      } */

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const [notifications, setNotifications] = useState([]);

  const getDaysUntil = (date) => {
    if (!date) return null;
    const today = new Date();
    const targetDate = new Date(date);
    return Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const expiringContracts = contracts.filter(contract => {
      const daysUntilExpiry = getDaysUntil(contract.through);
      const daysUntilNotice = getDaysUntil(contract.notificationDeadline);

      // Only include contracts with valid data
      if (!contract.name && !contract.label) return false;

      // Notify if contract expires in 30 days or less or if notice deadline is critical
      return (daysUntilExpiry <= 30) || (daysUntilNotice <= 30);
    });
    setNotifications(expiringContracts);
  }, [contracts]);

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  async function updateUser(id, name, journey_id) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/updateuser`;
    try {
      const response = await axios.put(url, {
        id,
        user_id: user.id,
        name
      });
      console.log('User', response.data); // Assuming you want to log each successful response
      if (response.data?.user_metadata?.company_id) {
        console.log('company id', response.data.user_metadata.company_id);
        handleLoginAuth0();
      }
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }
  const handleCompanyChange = (event) => {
    console.log('event', event);
    const selectedCompany = companies.find(c => c.id === event.value);
    updateUser(selectedCompany.id, selectedCompany.name, journey.id)
      .then(() => {
        dispatchredux(getCompany(selectedCompany.id))
        .then(() => {
          dispatchredux({ type: 'workplace/clearSpacebricks' }); // You'll need to add this action

          // Then fetch new data for the new company
          if (journey?.id) {
            dispatchredux(getJourneySpacebricks({ journey_id: journey.id }));
          }
          //console.log('got spacebricks, reloading', spacebricks);
          // Finally reload the page
          // window.location.reload();
        });
      });
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      {notifications.map((contract, index) => {
        const daysUntilExpiry = getDaysUntil(contract.through);
        const daysUntilNotice = getDaysUntil(contract.notificationDeadline);
        const isExpiring = daysUntilExpiry <= 30;

        return (
          <NotificationItem
            key={contract.id || index}
            image={<Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>warning</Icon>}
            title={[
              `Contract: ${contract.name || contract.label}`,
              `Status: ${isExpiring ? 'Expiring Soon' : 'Critical Notice'}`
            ]}
            date={`${isExpiring ? daysUntilExpiry : daysUntilNotice} days remaining`}
            onClick={handleCloseMenu}
          />
        );
      })}
    </Menu>
  );

  return (
    <AppBar
      position={"sticky"}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => ({
        ...navbarContainer(theme),
        position: 'relative'
      })}>
        <SoftBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => ({
            ...navbarRow(theme, { isMini }),
            position: 'relative',
            left: miniSidenav ? '-9rem' : 0,
            transition: 'left 200ms ease-in-out',
            display: 'flex',
            alignItems: 'center'
          })}
        >
          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
          <SoftBox pr={1}>
            {contract?.id && (
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  height: '100%',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,0,0,0.02)',
                  borderRadius: 1,
                  px: 2,
                  py: 0.5
                }}
              >
                <Icon
                  sx={{
                    color: 'text.secondary',
                    fontSize: '1.25rem'
                  }}
                >
                  description
                </Icon>
                <Box>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color="text.primary"
                  >
                    {contract?.name}
                  </SoftTypography>
                  <Link to="/" style={{ textDecoration: 'none' }}>
                    <SoftTypography
                      variant="caption"
                      color="primary"
                      sx={{
                        display: 'block',
                        '&:hover': { textDecoration: 'underline' }
                      }}
                    >
                      Switch Site
                    </SoftTypography>
                  </Link>
                </Box>
                <IconButton
                  size="small"
                  sx={{
                    ml: 'auto',
                    color: 'primary.main'
                  }}
                  component={Link}
                  to="/profile/overview"
                >
                  <Icon fontSize="small">chevron_right</Icon>
                </IconButton>
              </Stack>
            )}
          </SoftBox>
          {/*           <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} name={name} />
 */}        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Stepper
                activeStep={currentStep}
                alternativeLabel
                sx={{
                  flexGrow: 3,
                  my: 0,
                  mt: -1.5,
                  height: 'fit-content',
                  alignItems: 'center',
                  minHeight: 'unset',
                  mx: 'auto',
                  width: '40%',
                  position: 'absolute',
                  left: '55%',
                  transform: 'translateX(-50%)',
                  zIndex: 0,
                  maxWidth: 'calc(100% - 500px)'
                }}
              >
                {["Prospect", "Survey", "Understand", "Strategize", "Program", "Solution"].map((label, index) => (
                  <Step key={label} sx={{ cursor: 'pointer' }} onClick={() => handleStepClick(index)}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5, height: '100%' }}>
              {user?.isAdmin ? (
                <SoftSelect
                  value={company?.id ? { label: company.name, value: company.id } : ''}
                  onChange={handleCompanyChange}
                  options={companies.map((comp) => ({
                    label: comp.name,
                    value: comp.id
                  }))}
                  sx={{ minWidth: 200 }}
                />
              ) : (
                <Stack sx={{ display: 'flex', height: '100%' }}>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                  >
                    {user?.displayName}
                  </SoftTypography>

                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {user?.companyName}
                  </Typography>
                </Stack>

              )}
              <AccountPopover company={company} />
              <IconButton onClick={handleOpenMenu}>
                <Badge badgeContent={notifications.length} color="error">
                  <Icon>notifications</Icon>
                </Badge>
              </IconButton>
              {renderMenu()}
            </Stack>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  name: '',
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  name: PropTypes.string,
  onCompanyChange: PropTypes.func,
};

export default DashboardNavbar;
