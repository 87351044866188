import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingLandlords: false,
  isLoadingTurnovers: false,
  error: false,
  newCompany: '',
  contact: '',
  contract: '',
  contracts: [],
  companies: [],
  cpyneeds: [],
  matches: [],
  cpyactivities: [],
  cpymissions: [],
  contacts: [],
  data: null,
  company: null,
  index: 0,
  step: 25,
  after: 0,
  hasMore: true,
  maxLength: '',
  departments: [],
  demouse: null,
};

const slice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    zeroDepartments(state) {
      state.departments = [];
    },
    startLoadingLandlords(state) {
      state.isLoadingLandlords = true;
    },
    startLoadingTurnovers(state) {
      state.isLoadingTurnovers = true;
    },
    // GET POST INFINITE
    getCompaniesInitial(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.companies = [...state.companies, ...action.payload.results];
    },
    // GET POST INFINITE
    getCompaniesZero(state, action) {
      state.isLoading = false;
      console.log('zero');
      console.log(action.payload);
      state.companies = action.payload.results;
    },
    getMoreCompanies(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },
    getMoreRetailCompanies(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    // GET POST INFINITE
    getCompanies(state, action) {
      state.isLoading = false;
      console.log(action.payload);
      state.companies = [...state.companies, ...action.payload.companies];
      state.maxLength = action.payload.rowcount;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getCompaniesInternalSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload.companies.sort((a, b) => (b.name < a.name ? 1 : -1));
    },
    getCompaniesSuccess(state, action) {
      state.isLoading = false;
      state.companies = action.payload;
    },

    getContractsSuccess(state, action) {
      state.isLoading = false;
      state.contracts = action.payload;
      if (!state.contract) {
        // TODO: UPDATE TO BE UNINAMIOUS
        console.log('default contract is being set');
        state.contract = action.payload[0];
      }
    },
    getCpySuccess(state, action) {
      state.isLoading = false;
      console.log('cpy success', action.payload);
      state.data = action.payload;
      // console.log('loading companies', action.payload);
    },

    // GET POST
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },
    // GET POST
    getCompanyActivitiesSuccess(state, action) {
      state.isLoading = false;
      state.cpyactivities = action.payload.activities;
      state.cpymissions = action.payload.missions;
    },
    // GET POST
    getCompanyMatchSuccess(state, action) {
      state.isLoading = false;
      state.matchingprojects = action.payload.matchingprojects;
      state.matchingneeds = action.payload.matchingneeds;
      state.cpyneeds = action.payload.cpyneeds;
    },

    // GET POST
    getNeedMatchSuccess(state, action) {
      state.isLoading = false;
      state.matchingneedprojects = action.payload.matchingprojects;
    },
    // GET POST
    getContactsSuccess(state, action) {
      state.isLoading = false;
      state.contacts = action.payload;
    },
    // GET NEEDS
    getMatchesSuccess(state, action) {
      state.isLoading = false;
      state.matches = action.payload;
    },
    // GET NEEDS
    createCompanySuccess(state, action) {
      state.isLoading = false;
      // console.log(action.payload);
      state.newCompany = action.payload;
    },
    // GET NEEDS
    createContactSuccess(state, action) {
      state.isLoading = false;
      // console.log(action.payload);
      state.contact = action.payload;
    },
    getDepartmentsSuccess(state, action) {
      state.isLoading = false;
      state.departments = action.payload;
    },
    // GET NEEDS
    createNoteSuccess(state, action) {
      state.isLoading = false;
      // console.log(action.payload);
      state.newNote = action.payload;
    },
    noHasMore(state) {
      state.hasMore = false;
    },
    hasMore(state, action) {
      state.after = action.payload;
    },
    setDemouse(state, action) {
      state.demouse = action.payload;
    },
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { getMoreCompanies, getMoreRetailCompanies, setDemouse } = slice.actions;

// ----------------------------------------------------------------------

export function getAllCompanies() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/companies/jindex');
      console.log('companies', response.data);
      dispatch(slice.actions.getCompaniesSuccess(response.data.companies));
      //  dispatch(slice.actions.getTenantsSuccess(response.data.tenants.results));
      //  dispatch(slice.actions.getCpySuccess(response.data));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanyContracts(company_id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/contracts/companyindex/${company_id}`);
      console.log('contracts received', response.data);
      dispatch(slice.actions.getContractsSuccess(response.data));
    } catch (error) {
      console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getInternalCompanies() {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('https://plejsapp-api.herokuapp.com/api/v1/companies/internalindex');
      console.log('internalcompy', response);
      dispatch(slice.actions.getCompaniesInternalSuccess(response.data));
      //  dispatch(slice.actions.getTenantsSuccess(response.data.tenants.results));
      //  dispatch(slice.actions.getCpySuccess(response.data));
    } catch (error) {
      // console.log('error');
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanies(index, step) {
  return async (dispatch) => {

    try {
      const response = await axios({
        method: 'get',
        url: 'https://plejsapp-api.herokuapp.com/api/v1/companies/more',
        params: {
          idx: index,
          step,
        }
      });
      console.log(response);
      const results = index + response.data.companies.length;
      const { rowcount } = response.data;
      dispatch(slice.actions.getCompanies(response.data));
      if (results >= rowcount) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(id) {
  console.log('getCompany', id);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/company/${id}`, {
        params: { id }
      });
      console.log('response', response);
      console.log('company fetched', response.data.company);
      dispatch(slice.actions.getCompanySuccess(response.data.company));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompanyMatch(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/match/${id}`, {
        params: { id }
      });
      console.log(response.data);
      dispatch(slice.actions.getCompanyMatchSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createNote(note, company) {
  console.log(company);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/createnote`, {
        params: { id: company, timestamp: Date.now(), body: note }
      });
      dispatch(slice.actions.createNoteSuccess(response.data));
      // console.log(response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function createCompany(pro) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/companies/creat`, {
        params: { domain: pro.domain, name: pro.name, nickname: pro.nickname, type: pro.type }
      });
      dispatch(slice.actions.createCompanySuccess(response.data));
      // console.log(response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getMatches(catchment) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/needs/getmatches`, {
        params: { catchment }
      });
      // console.log('Matches', response);
      dispatch(slice.actions.getMatchesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateCompany(company_id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/companies/update/${company_id}`, params);
      console.log('updated', response);
      dispatch(getCompany(company_id));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateContract(id, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/contracts/update/${id}`, params);

    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function createDepartment(company_id, department) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/departments/create`, {
        params: { company_id, department }
      });
      dispatch(slice.actions.createCompanySuccess(response.data));
      // console.log(response);
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDepartments(company_id) {
  console.log('getDepartments', company_id);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/departments/companyindex/${company_id}`, {
        params: { company_id }
      });
      console.log('depi', response.data);
      dispatch(slice.actions.getDepartmentsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

export function getJourneyDepartments(journey_id) {
  console.log('getJourneyDepartments', journey_id);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.zeroDepartments());
    try {
      const response = await axios.get(`https://plejsapp-api.herokuapp.com/api/v1/departments/journeyindex/${journey_id}`, {
        params: { journey_id }
      });
      console.log('depi journey', response.data);
      dispatch(slice.actions.getDepartmentsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

// ----------------------------------------------------------------------

export function updateDepartment(department_id, params, journey = false) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`https://plejsapp-api.herokuapp.com/api/v1/departments/update/${department_id}`, params);
      console.log('updated', response);
      dispatch(slice.actions.startLoading());
      if (journey) {
        dispatch(getJourneyDepartments(response.data.journey_id));
      } else {
        dispatch(getDepartments(response.data.company_id));
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
