import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDemouse } from '../redux/slices/company';

export default function DemoGuard({ children }) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (pathname.startsWith('/demo')) {
      dispatch(setDemouse(true));
    }
  }, [pathname, dispatch]);

  return children;
}