import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import formquestions from "../../components/_dashboard/sampledata/formquestions.json";
import formquestionsmgmt from "../../components/_dashboard/sampledata/formquestionsmgmtnew.json";
import { filloutConfig } from "../../config";
import { workplacerMagicManager } from "../../utils/workplacerMgmtMagic";
import { updateJourney } from "./deal";
import { updateProspect } from "./site";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fullyErgonomicDesksRatio: 0.8,
  smallRoomsRatio: 0.15,
  largeRoomsRatio: 0.05,
  totalSeatsRatio: 2,
  noSurveys: false,
  deepDive: null,
  midpoint: null,
  deepdives: [],
  midpoints: []
};

const slice = createSlice({
  name: "workplace",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    
    // GET POST INFINITE
    getSurveySuccess(state, action) {
      state.isLoadingSurvey = false;
      console.log("survey payload", action.payload);
      state.survey = action.payload;
    },

    // GET POST INFINITE
    getDeepDiveSuccess(state, action) {
      state.isLoading = false;
      console.log("deep dive payload", action.payload);
      state.deepDive = action.payload;
    },
    getDeepDivesSuccess(state, action) {
      state.isLoading = false;
      console.log("deep dives payload", action.payload);
      const deepdives = action.payload?.filter((d) => d.isCompleted);
      state.deepdives = deepdives;
    },
    getDeepDivesSuccess(state, action) {
      state.isLoading = false;
      console.log("deep dives payload", action.payload);
      const deepdives = action.payload?.filter((d) => d.isCompleted);
      state.deepdives = deepdives;
    },
    getMidpointsSuccess(state, action) {
      state.isLoading = false;
      console.log("midpoints payload", action.payload);
      const midpoints = action.payload?.filter((d) => d.isCompleted);
      state.midpoints = midpoints;
    },
    getMidpointSuccess(state, action) {
      state.isLoading = false;
      console.log("midpoint payload", action.payload);;
      state.midpoint = action.payload;
    },
    // GET POST INFINITE
    getSurveyAnswersSuccess(state, action) {
      state.isLoadingSurveyAnswers = false;
      console.log("survey answers payload", action.payload);
      state.answers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
// ----------------------------------------------------------------------
/* 
export function getJourneyMgmtSubmissions(company_id, journey_id, workplaces, hasSubmission) {
  console.log("Starting to fetch mgmt submissions", company_id, journey_id, workplaces);
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMgmtSubmissions());
    try {
      const token = filloutConfig;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        "https://api.fillout.com/v1/api/forms/hy1YdhaK8Cus/submissions",
        config
      );
      //console.log("submissions mgmt,", response.data.responses.map((d) => d.urlParameters));
      const filtered = response.data.responses.filter(
        (submission) => submission.urlParameters[1].value === `${company_id}` && submission.urlParameters[5].value === `${journey_id}`
      );
      if (filtered.length === 0) {
        dispatch(slice.actions.getEmptyMgmtSubmissionsSuccess());
      } else {

        const resp = filtered[filtered.length - 1];
        console.log('journey specific submissions', resp);
        if (!hasSubmission) {
          dispatch(updateJourney(journey_id, { submission_id: resp.submissionId }))
        }
        const respi = { ...resp, workplaces };
        dispatch(slice.actions.getMgmtSubmissionsSuccess(respi));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getProspectMgmtSubmissions(company_id, prospect_id, workplaces, hasSubmission) {
  console.log("Starting to fetch mgmt submissions", company_id, prospect_id, workplaces);
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMgmtSubmissions());
    try {
      const token = filloutConfig;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        "https://api.fillout.com/v1/api/forms/hy1YdhaK8Cus/submissions",
        config
      );
      //console.log("submissions mgmt,", response.data.responses.map((d) => d.urlParameters));
      const urlParams = response.data.responses.map((d) => ({id: d.submissionId, urlParams: d.urlParameters}))
      console.log('urlParams', urlParams, prospect_id);
      const filtered = response.data.responses.filter(
        (submission) => submission.urlParameters[1].value === `${company_id}` && submission.urlParameters.find((up) => up.name === 'prospect_id').value === `${prospect_id}`
      );
      if (filtered.length === 0) {
        dispatch(slice.actions.getEmptyMgmtSubmissionsSuccess());
      } else {

        const resp = filtered[filtered.length - 1];
        console.log('journey specific submissions', resp);
        if (!hasSubmission) {
          //dispatch(updateProspect(prospect_id, { submission_id: resp.submissionId }))
        }
        const respi = { ...resp, workplaces };
        dispatch(slice.actions.getMgmtSubmissionsSuccess(respi));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
} */

// ----------------------------------------------------------------------

export function getDeepDive(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/deepdives/deepdive/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getDeepDiveSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyDeepDives(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/deepdives/journeyindex/${id}`
      );
      console.log("avt journeydeepdives,", response);
      dispatch(slice.actions.getDeepDivesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyMidpoints(id) {
  console.log("loading journey midpoints");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/midpoints/journeyindex/${id}`
      );
      console.log("avt midpoints,", response);
      dispatch(slice.actions.getMidpointsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMidpoint(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/midpoints/midpoint/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getMidpointSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getWorkplaceprofiles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/workplaceprofiles/index`
      );
      dispatch(slice.actions.getWorkplaceprofilesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/workplaces/workplace/${id}`,
        {
          params: { id },
        }
      );
      dispatch(slice.actions.getWorkplaceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
